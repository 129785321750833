import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import "../../../assets/styles/pages/vocWebsite.scss";
import VocDashboardFilters from "../../../components/Filters/VocDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";

const Sprinklr = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageName(""));
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<VocDashboardFilters filterBtns={VOC_BTN_FILTERS} inComplete />}
        dataDocumentation="voc_website"
        lastDataRefresh={""}
        pageTitle={<PageTitle dataDocumentation="voc_website" />}
      >
        <div className="no-data-tile">This dashboard page is in development and will be available soon in CEDAR home</div>
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default Sprinklr;
