import React from "react";
import { Tooltip } from "react-tippy";
import Ratings from "../../../components/Ratings/Ratings";
import { convertToAbsolutePts, isNull } from "../../../utils/utilityFunctions";

interface Props {
  title: string;
  tooltipText?: string[];
  indicatorId: string;
  yoyValue: number | null | string;
  yoyLabel: string;
  latestMonthValue: number | null | string;
  latestMonthLabel: string;
  yoyComparisonValue: number | null;
  latestMonthMoMValue: number | null;
  latestMonthYoYValue: number | null;
  showPercentageComparison?: boolean;
  targetVariance?: number | null;
  targetPercentage?: number | null;
  targetVariancelastMonth?: number | null;
  targetPercentageLastmonth?: number | null;
  isPrivateSales?: boolean;
  privateSalesValue?: number | null;
  privateSalesYoY?: number | null;
  privateSalesLastMonth?: number | null;
  privateSalesLastMonthMoM?: number | null;
  privateSalesLastMonthYoY?: number | null;
  digitallyInfluencedSalesValue?: number | null | string;
  digitallyInfluencedSalesYoY?: number | null;
  digitallyInfluencedLastMonth?: number | null;
  digitallyInfluencedLastMonthMoM?: number | null;
  digitallyInfluencedLastMonthYoY?: number | null;
}

export const PurchaseFunnelKeyIndicator = (props: Props) => {
  const {
    title,
    tooltipText,
    indicatorId,
    yoyValue,
    yoyLabel,
    latestMonthValue,
    latestMonthLabel,
    yoyComparisonValue,
    latestMonthMoMValue,
    latestMonthYoYValue,
    showPercentageComparison = true,
    targetVariance,
    targetPercentage,
    targetVariancelastMonth,
    targetPercentageLastmonth,
    isPrivateSales,
    privateSalesValue,
    privateSalesYoY,
    privateSalesLastMonth,
    privateSalesLastMonthMoM,
    privateSalesLastMonthYoY,
    digitallyInfluencedSalesValue,
    digitallyInfluencedSalesYoY,
    digitallyInfluencedLastMonth,
    digitallyInfluencedLastMonthMoM,
    digitallyInfluencedLastMonthYoY,
  } = props;

  return (
    <div className={"pf_key_indicator_container"} id={indicatorId} data-test-id={indicatorId}>
      <div className="pf_duration_header">
        <p
          className="main_value_label"
          id={`${indicatorId}_yoy_label`}
          data-test-id={`${indicatorId}_yoy_label`}
          style={{ marginLeft: showPercentageComparison ? 0 : 5 }}
        >
          {yoyLabel}
        </p>
        <span className="main_value_label" id={`${indicatorId}_month_label`} data-test-id={`${indicatorId}_month_label`}>
          {latestMonthLabel}
        </span>
      </div>

      <div className="pf_duration_header" style={{ margin: 0 }}>
        <div className="pf_key_indicator_header">
          <div>
            <p>{title}</p>
          </div>
          {tooltipText && (
            <div style={{ marginTop: 5 }}>
              <Tooltip
                position="top"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                html={
                  <div>
                    <p>{tooltipText[0]}</p>
                    <p>{tooltipText[1]}</p>
                  </div>
                }
                size={"small"}
              >
                <span className="info_tooltip"></span>
              </Tooltip>
            </div>
          )}
        </div>
        <span className="main_value_label" id={`${indicatorId}_month_label`} data-test-id={`${indicatorId}_month_label`}>
          {title}
        </span>
      </div>
      <div className="values">
        <div className="current_values">
          <p className="main_value">
            <span className="main_value_number" id={`${indicatorId}_yoy_value`} data-test-id={`${indicatorId}_yoy_value`}>
              {yoyValue?.toLocaleString() ?? "n/a"}
              {!isNull(yoyValue) && !showPercentageComparison ? "%" : ""}
            </span>
            {!isNull(targetPercentage) && targetPercentage != undefined ? (
              <span className="main_target_comparison">(Target: {targetPercentage}%)</span>
            ) : null}
          </p>

          <div className="comparison_values">
            <p
              className="period_comparison"
              id={`${indicatorId}_period_yoy_comparison`}
              data-test-id={`${indicatorId}_period_yoy_comparison`}
            >
              {<Ratings value={yoyComparisonValue ? yoyComparisonValue : "n/a"} isPercentageValue={false} testValue={false} />}
              <span
                className="comparison_value"
                id={`${indicatorId}_yoy_comparison_value`}
                data-test-id={`${indicatorId}_yoy_comparison_value`}
              >
                {showPercentageComparison && yoyComparisonValue
                  ? `${Math.abs(yoyComparisonValue).toFixed(1)}%`
                  : yoyComparisonValue
                  ? convertToAbsolutePts(yoyComparisonValue, 1)
                  : "n/a"}
              </span>
              {!isNull(yoyComparisonValue) ? "YoY" : ""}
            </p>
            <p className="period_comparison" id={`${indicatorId}_target_pts`} data-test-id={`${indicatorId}_target_pts`}>
              {targetVariance && <Ratings value={targetVariance ? targetVariance : "n/a"} isPercentageValue={false} testValue={false} />}
              <span
                className="comparison_value"
                id={`${indicatorId}_target_pts_comparison`}
                data-test-id={`${indicatorId}_target_pts_comparison`}
              >
                {targetVariance ? targetVariance : ""}
              </span>
              {targetVariance && "PTS vs target"}
            </p>
          </div>
        </div>

        <div className="latest_month_values">
          <p className="main_value">
            <span className="main_value_number" id={`${indicatorId}_month_value`} data-test-id={`${indicatorId}_month_value`}>
              {latestMonthValue?.toLocaleString() ?? "n/a"}
              {!isNull(latestMonthValue) && !showPercentageComparison ? "%" : ""}
            </span>

            {!isNull(targetPercentageLastmonth) && targetPercentageLastmonth != undefined ? (
              <span className="main_target_comparison">(Target: {targetPercentageLastmonth}%)</span>
            ) : null}
          </p>

          <div className="comparison_values">
            <p className="period_comparison" id={`${indicatorId}_period_month_yoy`} data-test-id={`${indicatorId}_period_month_yoy`}>
              {<Ratings value={latestMonthYoYValue ? latestMonthYoYValue : "n/a"} isPercentageValue={false} testValue={false} />}
              <span className="comparison_value" id={`${indicatorId}_month_yoy_value`} data-test-id={`${indicatorId}_month_yoy_value`}>
                {showPercentageComparison && latestMonthYoYValue
                  ? `${Math.abs(latestMonthYoYValue).toFixed(1)}%`
                  : latestMonthYoYValue
                  ? convertToAbsolutePts(Number(latestMonthYoYValue), 1)
                  : "n/a"}
              </span>
              {!isNull(latestMonthYoYValue) ? "YoY" : ""}
            </p>

            <p className="period_comparison" id={`${indicatorId}_period_month`} data-test-id={`${indicatorId}_period_month`}>
              {<Ratings value={latestMonthMoMValue ? latestMonthMoMValue : "n/a"} isPercentageValue={false} testValue={false} />}
              <span className="comparison_value" id={`${indicatorId}_month_comparison`} data-test-id={`${indicatorId}_month_comparison`}>
                {showPercentageComparison && latestMonthMoMValue
                  ? `${Math.abs(latestMonthMoMValue).toFixed(1)}%`
                  : latestMonthMoMValue
                  ? convertToAbsolutePts(Number(latestMonthMoMValue), 1)
                  : "n/a"}
              </span>
              {latestMonthMoMValue && "MoM"}
            </p>

            <p
              className="period_comparison"
              id={`${indicatorId}last_month_target_pts`}
              data-test-id={`${indicatorId}last_month_target_pts`}
            >
              {targetVariancelastMonth && (
                <Ratings value={targetVariancelastMonth ? targetVariancelastMonth : "n/a"} isPercentageValue={false} testValue={false} />
              )}
              <span
                className="comparison_value"
                id={`${indicatorId}_last_month_target_pts_comparison`}
                data-test-id={`${indicatorId}_last_month_target_pts_comparison`}
              >
                {targetVariancelastMonth ? targetVariancelastMonth : ""}
              </span>
              {targetVariancelastMonth && <span>PTS vs target</span>}
            </p>
          </div>
        </div>
      </div>

      <div className="pf_duration_header" style={{ margin: 0 }}>
        <p
          className="main_value_label"
          id={`${indicatorId}_yoy_label`}
          data-test-id={`${indicatorId}_yoy_label`}
          style={{ marginLeft: showPercentageComparison ? 0 : 5 }}
        >
          {isPrivateSales ? "Private Sales" : "Digitally influenced sales"}
        </p>
        <span className="main_value_label" id={`${indicatorId}_month_label`} data-test-id={`${indicatorId}_month_label`}>
          {isPrivateSales ? "Private Sales" : "Digitally influenced sales"}
        </span>
      </div>

      <div className="values">
        <div className="current_values">
          <p className="main_value">
            <span className="main_value_number" id={`${indicatorId}_yoy_value`} data-test-id={`${indicatorId}_yoy_value`}>
              {isPrivateSales ? privateSalesValue?.toLocaleString() ?? "n/a" : digitallyInfluencedSalesValue?.toLocaleString() ?? "n/a"}
            </span>
          </p>

          <div className="comparison_values">
            <p
              className="period_comparison"
              id={`${indicatorId}_period_yoy_comparison`}
              data-test-id={`${indicatorId}_period_yoy_comparison`}
            >
              {
                <Ratings
                  value={isPrivateSales ? (privateSalesYoY as number) : (digitallyInfluencedSalesYoY as number)}
                  isPercentageValue={false}
                  testValue={false}
                />
              }
              <span
                className="comparison_value"
                id={`${indicatorId}_yoy_comparison_value`}
                data-test-id={`${indicatorId}_yoy_comparison_value`}
              >
                {isPrivateSales
                  ? privateSalesYoY
                    ? `${Math.abs(privateSalesYoY).toFixed(1)}%`
                    : "n/a"
                  : digitallyInfluencedSalesYoY
                  ? `${Math.abs(digitallyInfluencedSalesYoY).toFixed(1)}%`
                  : "n/a"}
              </span>
              {(privateSalesYoY || digitallyInfluencedSalesYoY) && "YoY"}
            </p>
          </div>
        </div>

        <div className="latest_month_values">
          <p className="main_value">
            <span className="main_value_number" id={`${indicatorId}_month_value`} data-test-id={`${indicatorId}_month_value`}>
              {isPrivateSales ? privateSalesLastMonth?.toLocaleString() ?? "n/a" : digitallyInfluencedLastMonth?.toLocaleString() ?? "n/a"}
            </span>
          </p>

          <div className="comparison_values">
            <p className="period_comparison" id={`${indicatorId}_period_month_yoy`} data-test-id={`${indicatorId}_period_month_yoy`}>
              {
                <Ratings
                  value={
                    isPrivateSales && privateSalesLastMonthYoY
                      ? privateSalesLastMonthYoY
                      : digitallyInfluencedLastMonthYoY
                      ? digitallyInfluencedLastMonthYoY
                      : "n/a"
                  }
                  isPercentageValue={false}
                  testValue={false}
                />
              }
              <span className="comparison_value" id={`${indicatorId}_month_yoy_value`} data-test-id={`${indicatorId}_month_yoy_value`}>
                {isPrivateSales
                  ? privateSalesLastMonthYoY
                    ? `${Math.abs(privateSalesLastMonthYoY).toFixed(1)}%`
                    : "n/a"
                  : digitallyInfluencedLastMonthYoY
                  ? `${Math.abs(digitallyInfluencedLastMonthYoY).toFixed(1)}%`
                  : "n/a"}
              </span>
              {(privateSalesLastMonthYoY || digitallyInfluencedLastMonthYoY) && "YoY"}
            </p>

            <p className="period_comparison" id={`${indicatorId}_period_month`} data-test-id={`${indicatorId}_period_month`}>
              {
                <Ratings
                  value={
                    isPrivateSales && privateSalesLastMonthMoM
                      ? privateSalesLastMonthMoM
                      : digitallyInfluencedLastMonthMoM
                      ? digitallyInfluencedLastMonthMoM
                      : "n/a"
                  }
                  isPercentageValue={false}
                  testValue={false}
                />
              }
              <span className="comparison_value" id={`${indicatorId}_month_comparison`} data-test-id={`${indicatorId}_month_comparison`}>
                {isPrivateSales
                  ? privateSalesLastMonthMoM
                    ? `${Math.abs(privateSalesLastMonthMoM).toFixed(1)}%`
                    : "n/a"
                  : digitallyInfluencedLastMonthMoM
                  ? `${Math.abs(digitallyInfluencedLastMonthMoM).toFixed(1)}%`
                  : "n/a"}
              </span>
              {(privateSalesLastMonthMoM || digitallyInfluencedLastMonthMoM) && "MoM"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
