import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getVocWebsiteDataDefinitions } from "../../../api/vocApi";

interface DataUpdate {
  Section: string;
  KPI: string;
  Definition: string;
  prompt: string;
}

const VocData = () => {
  const [dataUpdates, setDataUpdates] = useState<Array<DataUpdate>>();

  useEffect(() => {
    getVocWebsiteDataDefinitions().then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setDataUpdates(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>VOC - WEBSITE - DATA OVERVIEW</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>Section</th>
            <th>KPI</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.Section}</td>
              <td>{data.KPI}</td>
              <td>{data.Definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};

export default VocData;
