import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setParameters, setSpecificParameter } from "../../../actions";
import { getExecutiveSummaryProductPerformanceFilters } from "../../../api";
import "../../../assets/styles/component/filters.scss";
import { DEFAULT_EXECUTIVE_SUMMARY_DIS, DEFAULT_PURCHASE_FUNNEL_PARAMETERS, DIS_PRESET_DATE_PARAMS } from "../../../constants";
import { FilterContext } from "../../../context";
import { isMonthlyCustomDate } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { ErrorMsg } from "../../AppMessages";
import { FiltersLayout } from "../../Layouts";
import { DefaultFilter, MultiSelectFilter } from "../common";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  inComplete?: boolean;
}

const ExecutiveSummaryProductPerformanceFilters = withRouter((props: RouteComponentProps & Props) => {
  const { history, filterBtns } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    product: productParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const [marketFilterValue, setMarketFilterValue] = useState(marketParamValue);
  const [productFilterValue, setProductFilterValue] = useState(productParamValue);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Executive Summary - Product Performance");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_EXECUTIVE_SUMMARY_DIS));
      currentDefaultFiltersSet("Executive Summary - Product Performance");
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["filtersData", history.location.search],
    queryFn: getExecutiveSummaryProductPerformanceFilters,
    initialData: {},
    enabled: false,
  });

  const { brand, market: markets, product: products } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_EXECUTIVE_SUMMARY_DIS));
  }, []);

  //Reset date range param value
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate = DIS_PRESET_DATE_PARAMS.includes(dateRangeParamValue) || isMonthlyCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "This financial year"));
    } else {
      dispatch(setSpecificParameter("date_range", "This financial year"));
    }
  }, [dateRangeParamValue]);

  //Validate market param
  useEffect(() => {
    if (markets && markets.length > 0 && marketParamValue != "All") {
      const selectedMarkets = marketParamValue.split(",");
      if (selectedMarkets.every((market: string) => markets.includes(market))) return;
      const validMarkets: Array<string> = [];
      selectedMarkets.forEach((market: string) => markets.includes(market) && validMarkets.push("market"));
      validMarkets.length > 0
        ? dispatch(setSpecificParameter("market", validMarkets.join(",")))
        : dispatch(setSpecificParameter("market", "All"));
    }
  }, [marketParamValue, markets]);

  //Validate product param
  useEffect(() => {
    if (products && products.length > 0 && productParamValue != "All") {
      const selectedProducts = productParamValue.split(",");
      if (selectedProducts.every((product: string) => products.includes(product))) return;
      const validProducts: Array<string> = [];
      selectedProducts.forEach((product: string) => products.includes(product) && validProducts.push("product"));
      validProducts.length > 0
        ? dispatch(setSpecificParameter("product", validProducts.join(",")))
        : dispatch(setSpecificParameter("product", "All"));
    }
  }, [productParamValue, products]);

  // Set market filter value
  useEffect(() => {
    if (markets && marketParamValue) {
      const selectedMarketsArr = marketParamValue.split(",");
      const marketSourceValue =
        selectedMarketsArr?.length === markets.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} market selected`
          : marketParamValue;

      setMarketFilterValue(marketSourceValue);
    }
  }, [markets, marketParamValue]);

  // Set product filter value
  useEffect(() => {
    if (products && productParamValue) {
      const selectedProductsArr = productParamValue.split(",");
      const productSourceValue =
        selectedProductsArr?.length === products.length
          ? "All"
          : selectedProductsArr?.length > 1
          ? `${selectedProductsArr?.length} product selected`
          : productParamValue;

      setProductFilterValue(productSourceValue);
    }
  }, [products, productParamValue]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Executive Summary",
        page: "Product Performance",
      });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === markets?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance",
    });
    closeFilterOptions();
  }, [markets]);

  const handleBrandFilterSubmission = useCallback(() => {
    const parameterName = "brand";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === brand?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance",
    });
    closeFilterOptions();
  }, [brand]);

  const handleProductFilterSubmission = useCallback(() => {
    const parameterName = "product";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === products?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance",
    });
    closeFilterOptions();
  }, [products]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    params.set("product", productParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, productParamValue]);

  return (
    <FiltersLayout extraClass={"iframeFilters-midas"} resetFilters={resetFilters} filterBtns={filterBtns}>
      <div className="filte">
        {/*Date range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName="brand"
            filterLabel="BRAND"
            filterValue={brandParamValue}
            list={brand ?? []}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {markets?.length > 1 ? (
            <MultiSelectFilter
              parentKey="market"
              filterName={"GEOGRAPHY"}
              // @ts-ignore
              filterList={markets ?? []}
              value={marketFilterValue}
              parameterName={"market"}
              parameterValue={marketParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleMarketFilterSubmission}
            />
          ) : (
            <DefaultFilter
              filterName={"market"}
              list={markets}
              filterValue={marketParamValue}
              filterLabel={"GEOGRAPHY"}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          )}
        </ErrorBoundary>

        {/* Lead Source */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="product"
            filterName={"PRODUCT"}
            // @ts-ignore
            filterList={products ?? []}
            value={productFilterValue}
            parameterName={"product"}
            parameterValue={productParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleProductFilterSubmission}
          />
        </ErrorBoundary>
      </div>
    </FiltersLayout>
  );
});

export default ExecutiveSummaryProductPerformanceFilters;
