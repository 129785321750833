import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setHasAriya, setHasAura, setHasQashqai, setModels, setOptionsList, setParameters, setSpecificParameter } from "../../actions";
import { getCpoFiltersData } from "../../api";
import "../../assets/styles/component/filters.scss";
import {
  ALL_OPTION,
  ALL_OPTION_NO_SPACE,
  ARIYA,
  AURA,
  DEFAULT_CPO_PARAMETERS,
  DEFAULT_DATE_RANGE,
  MIN_SELECTABLE_DATE,
  NISSAN,
  OCE_DATE_FILTER_OPTIONS,
  QASHQAI_2021,
} from "../../constants";
import { checkFormattedGeographyData, ModelDataList } from "../../constants/interface";
import { FilterContext } from "../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DateFilter, DefaultFilter, ModelFilter, NewGeographyFilter } from "./common";
import { getGeographyList, getGroupList } from "./subs/filterFunctions";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

export const CpoFilters = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const dispatch = useDispatch();

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.digital_performance);
  const marketDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.market_specific_data_dates);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const groupParamValue = useSelector((state: RootStateOrAny) => state.parameters.group);
  const regionParamValue = useSelector((state: RootStateOrAny) => state.parameters.region);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.parameters.model);
  const granularityParamValue = useSelector((state: RootStateOrAny) => state.parameters.granularity);
  const specificKbaParamValue = useSelector((state: RootStateOrAny) => state.parameters.specific_kba);
  const specificLeadParamValue = useSelector((state: RootStateOrAny) => state.parameters.specific_lead);
  const channelParamValue = useSelector((state: RootStateOrAny) => state.parameters.channel);
  const channelBreakdownParamValue = useSelector((state: RootStateOrAny) => state.parameters.last_touch_marketing_channel_breakdown);

  const {
    data: filtersData,
    filterOptions: { group: groups, channel: channels },
  } = useSelector((state: RootStateOrAny) => state.filters);
  const cpoModels = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.cpo_grouped_data.data);
  const { data: modelData, hasAriya, hasQashqai, hasAura } = useSelector((state: RootStateOrAny) => state.models);
  const { data: channelData, list: availableChannels } = useSelector((state: RootStateOrAny) => state.marketing_channels);

  const [regionCountryValue, setRegionCountryValue] = useState(marketParamValue.includes("All") ? regionParamValue : marketParamValue);
  const [channelValue, setChannelValue] = useState(channelParamValue);
  const [testedGeographies, setTestedGeographies] = useState<checkFormattedGeographyData>([]);

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: dataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("CPO Online CE performance");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_CPO_PARAMETERS));
      currentDefaultFiltersSet("CPO Online CE performance");
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["cpoFiltersData", history.location.search],
    queryFn: getCpoFiltersData,
    initialData: {
      brand: [],
      market: [],
      model: [],
    },
    enabled: false,
  });

  const { brand: brands, market: geographies, model: models } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  // Set default date range values
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate: boolean = isDigitalPerformanceDate(dateRangeParamValue) || isCustomDate(dateRangeParamValue);

      if (!isValidDate) dispatch(setSpecificParameter("date_range", DEFAULT_DATE_RANGE));
    }
  }, [dateRangeParamValue]);

  // Resets japan region filter
  useEffect(() => {
    if (regionParamValue) {
      regionParamValue === "Japan" &&
        Promise.all([
          dispatch(setSpecificParameter("region", ALL_OPTION_NO_SPACE)),
          dispatch(setSpecificParameter("market", regionParamValue)),
        ]);

      regionParamValue === "North America" &&
        Promise.all([dispatch(setSpecificParameter("region", "Americas")), dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE))]);
    }
  }, [regionParamValue]);

  //Update marketDataAvailability when value when market value is updated
  useEffect(() => {
    if (marketParamValue in marketDataRefresh) {
      const selectedMarket = marketDataRefresh[marketParamValue][0];

      if (selectedMarket) {
        const selectedStart = moment(selectedMarket.start_date, "YYYY-MM-DD").isSameOrBefore(moment(MIN_SELECTABLE_DATE, "DD/MM/YYYY"))
          ? MIN_SELECTABLE_DATE
          : moment(selectedMarket.start_date, "YYYY-MM-DD").format("DD/MM/YYYY");

        setMarketDataAvailability({ start: selectedStart, end: moment(selectedMarket.end_date, "YYYY-MM-DD").format("DD/MM/YYYY") });
      }
    }
  }, [marketDataRefresh, marketParamValue]);

  // Sets available group and geography options
  useEffect(() => {
    const groupList = getGroupList(filtersData, brandParamValue);
    const geographyList = getGeographyList({ filters: filtersData, brand: brandParamValue, group: groupParamValue });
    dispatch(setOptionsList(groupList, "group"));
    dispatch(setOptionsList(geographyList, "geography"));
  }, [filtersData, brandParamValue, groupParamValue]);

  // Sets group parameter value
  useEffect(() => {
    if (groups && groups?.length > 0) {
      if (!groups.includes(groupParamValue)) {
        dispatch(setSpecificParameter("group", groups[0]));
      }
    }
  }, [groups, groupParamValue]);

  // useEffect(() => {
  //   if (geographies && regionParamValue && regionCountryValue) {
  //     const regions = geographies.map((geo: any) => geo.region);
  //     regionCountryValue === regionParamValue &&
  //       !regions.includes(regionCountryValue) &&
  //       Promise.all([
  //         dispatch(setSpecificParameter("region", ALL_OPTION_NO_SPACE)),
  //         dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE)),
  //       ]);
  //   }
  // }, [geographies, regionCountryValue, regionParamValue, dispatch]);

  //Set geography input value
  useEffect(() => {
    if (geographies && geographies?.length > 0) {
      const marketAllRe = new RegExp(ALL_OPTION_NO_SPACE, "gi");

      const geographyValue = !marketAllRe.test(marketParamValue)
        ? marketParamValue.includes(",")
          ? `${marketParamValue.split(",")?.length} markets selected`
          : marketParamValue
        : regionParamValue.includes(",")
        ? regionParamValue.split(",")?.length === geographies?.length - 1
          ? ALL_OPTION
          : `${regionParamValue.split(",")?.length} regions selected`
        : regionParamValue;
      setRegionCountryValue(geographyValue);

      const updatesGeographies = geoFormatting([{ region: "All " }, ...geographies], geographyValue);
      //@ts-ignore
      setTestedGeographies(updatesGeographies);
    }
  }, [geographies, regionParamValue, marketParamValue]);

  // Sets model filters list
  useEffect(() => {
    if (modelData && cpoModels.length && regionCountryValue) {
      const geoValue = regionCountryValue === "ASEAN/Japan" ? "ASEAN" : regionCountryValue;
      const multipleSelectionRe = new RegExp("selected", "gi");
      // If multiple regions/ markets are selected disable model Selection
      const isMultiValue = multipleSelectionRe.test(geoValue);
      const regionSelector = geoValue === ALL_OPTION || isMultiValue ? "Global" : geoValue;
      const modelList: ModelDataList =
        geoValue === ALL_OPTION || isMultiValue
          ? []
          : cpoModels?.filter((model: any) => Object.keys(model).includes(`${regionSelector} ${brandParamValue}`))[0]?.[
              `${regionSelector} ${brandParamValue}`
            ];
      const modelsArr = modelList?.map((row) => row.model);

      const ariyaRe = new RegExp("ariya", "gi");
      const qashqai2021Re = new RegExp("Qashqai 2021", "gi");
      const auraRe = new RegExp("Aura", "gi");

      dispatch(setHasAriya(false));
      dispatch(setHasQashqai(false));
      dispatch(setHasAura(false));

      modelsArr?.forEach((model: string) => {
        if (ariyaRe.test(model)) dispatch(setHasAriya(true));
        if (qashqai2021Re.test(model)) dispatch(setHasQashqai(true));
        if (auraRe.test(model)) dispatch(setHasAura(true));
      });

      const topModelList = modelsArr?.slice(0, 10);
      if (hasAriya && topModelList && !topModelList.includes(ARIYA)) topModelList.push(ARIYA);
      if (hasQashqai && topModelList && !topModelList.includes(QASHQAI_2021)) topModelList.push(QASHQAI_2021);
      if (hasAura && topModelList && !topModelList.includes(AURA) && geoValue === "Japan") topModelList.push(AURA);
      if (typeof topModelList === "undefined") return;

      if (typeof topModelList === "undefined") return;
      dispatch(setModels(topModelList));

      const modelFilterList = topModelList?.length >= 0 ? [ALL_OPTION_NO_SPACE, ...topModelList] : [ALL_OPTION_NO_SPACE];
      dispatch(setOptionsList(modelFilterList, "model"));
    }
  }, [brandParamValue, cpoModels, regionCountryValue, modelParamValue, modelData, hasAriya, hasQashqai, hasAura]);

  // reset model filter
  useEffect(() => {
    if ((models && modelParamValue === "ARIYA") || (modelParamValue === "Qashqai 2021" && models.includes(modelParamValue))) return;
    if (models && !models.includes(modelParamValue) && models.includes("ARIYA") && models.includes("Qashqai 2021") && models.length <= 3)
      return;
    if (models && !models.includes(modelParamValue) && models.includes("ARIYA") && models.length <= 2) return;
    if (models && models.length > 3 && models.includes(modelParamValue)) return;
  }, [regionCountryValue, modelParamValue, models]);

  // Set channel input value
  useEffect(() => {
    const channelInputValue = channelBreakdownParamValue !== ALL_OPTION ? channelBreakdownParamValue : channelParamValue;
    setChannelValue(channelInputValue);
  }, [channelParamValue, channelBreakdownParamValue]);

  // Reset channel on options change
  useEffect(() => {
    if (availableChannels?.length > 0) {
      if (!availableChannels?.includes(channelValue)) {
        dispatch(setSpecificParameter("last_touch_marketing_channel", ALL_OPTION));
        dispatch(setSpecificParameter("last_touch_marketing_channel_breakdown", ALL_OPTION));
      }
    }
  }, [availableChannels, channelValue]);

  useEffect(() => {
    if (/datsun/gi.test(brandParamValue)) dispatch(setSpecificParameter("brand", NISSAN));
  }, [brandParamValue]);

  const geoFormatting = (geos: { region: string; market?: string[] }[], geographyValue: string) => {
    return geos.map((geo) => {
      const { region, market: markets } = geo;

      let result: Record<string, unknown>;

      if (region === ALL_OPTION) {
        if (geographyValue === ALL_OPTION) {
          result = { region, indeterminate: false, checked: true };
        } else {
          result = { region, indeterminate: true, checked: false };
        }
      } else {
        const selectedMarkets = marketParamValue.split(",").filter((val: string) => markets?.includes(val));
        const isIndeterminate = markets && selectedMarkets?.length > 0 && selectedMarkets?.length < markets?.length;
        const isChecked = (markets && selectedMarkets?.length === markets?.length) || geographyValue == ALL_OPTION;

        result = { region, markets, checked: isChecked, indeterminate: isIndeterminate };
      }

      return result;
    });
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "CPO", page: location.pathname });

    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_CPO_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "CPO", page: "CPO" });

      if (filterName === "brand") {
        const brandValue = optionValue.toLowerCase().includes("datsun") ? NISSAN : optionValue; //Resets datsun to Nissan

        Promise.all([
          dispatch(setSpecificParameter(filterName, brandValue)),
          dispatch(setSpecificParameter("region", ALL_OPTION)),
          dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE)),
        ]);
      } else {
        dispatch(setSpecificParameter(filterName, optionValue));
      }
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("region", regionParamValue);
    params.set("market", marketParamValue);
    params.set("model", modelParamValue);
    params.set("granularity", granularityParamValue);
    specificKbaParamValue && params.set("specific_kba", specificKbaParamValue);
    specificLeadParamValue && params.set("specific_lead", specificLeadParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [
    dateRangeParamValue,
    brandParamValue,
    marketParamValue,
    regionParamValue,
    modelParamValue,
    granularityParamValue,
    specificKbaParamValue,
    specificLeadParamValue,
  ]);

  return (
    <FiltersLayout resetFilters={resetFilters} extraClass={"digital_filters"}>
      <>
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DateFilter
            onFilterClick={showFilterOptionsFn}
            renderOptions={onRenderOptions}
            value={dateRangeParamValue}
            maxDate={dataRefresh}
            availableDates={marketDataAvailability}
            dateOptions={OCE_DATE_FILTER_OPTIONS}
            page="Online CE performance"
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={brands}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/* Geography */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <NewGeographyFilter
            /* @ts-ignore */
            geographies={testedGeographies}
            onShowFilterOptions={showFilterOptionsFn}
            value={regionCountryValue}
            isCpoDashboard={true}
          />
        </ErrorBoundary>

        {/*Model*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ModelFilter
            filterValue={modelParamValue}
            list={models ? ["All", ...models] : ["All"]}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*App store*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            hidden={true}
            list={["All", "Andriod", "iOS"]}
            filterLabel={"APP STORE"}
            filterName="app_store"
            filterValue={"All"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>
      </>
    </FiltersLayout>
  );
});
