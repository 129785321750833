// Todo: Move some effects to sagas instead of useEffect
import Cookies from "js-cookie";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CockpitGeographyFilter } from ".";
import { fetchAppRatingsFiltersData, setSpecificParameter } from "../../actions";
import "../../assets/styles/component/filters.scss";
import { ALL_COUNTRIES, ALL_OPTION, ALL_OPTION_NO_SPACE } from "../../constants";
import { allAppRatingsServicesTitle, defaultAppRatingsServicesTitle, europeAppRatingsServicesTitle } from "../../constants/ccsConstants";
import { FilterContext } from "../../context";
import { checkConsecutiveMonths } from "../../pages/CCS Vital Signs/subs/utils";
import { groupByKeys } from "../../utils/computeFunctions";
import { FY_TWENTY } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, FilterBtns, MultiDateRangeWithPresets } from "./common";
import { getGeographyList } from "./subs/filterFunctions";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
}

const APP_STORE_FILTER_OPTIONS = ["All", "Android", "iOS"];

export const CcsAppRatingsFilters = withRouter((props: Props & RouteComponentProps) => {
  const { history, filterBtns } = props;

  const dispatch = useDispatch();

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const regionParamValue = useSelector((state: RootStateOrAny) => state.parameters.region);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const appParamValue = useSelector((state: RootStateOrAny) => state.parameters.app);
  const appStoreParamValue = useSelector((state: RootStateOrAny) => state.parameters.app_store);
  const topicParamValue = useSelector((state: RootStateOrAny) => state.parameters.topic);

  const { date_range, geography, topics, region_apps } = useSelector((state: RootStateOrAny) => state.filters.app_ratings_filters);

  const [marketOptionsVisible, setMarketOptionsVisible] = useState(false);

  const geographies = useMemo(() => getGeographyList({ filters: geography }), [geography]);

  const [dateParam, setDateParam] = useState<string>(dateRangeParamValue);
  const [regionParam, setRegionParam] = useState<string>(regionParamValue);
  const [marketParam, setMarketParam] = useState<string>(marketParamValue);

  // Filter input values
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<string>("");
  const [appStoreValue, setAppStoreValue] = useState<string>("");
  const [appValue, setAppValue] = useState<string>("");
  const [brandValue, setBrandValue] = useState<string>("");

  const [servicesTitle, setServicesTitle] = useState(defaultAppRatingsServicesTitle);

  const [geographyValue, setGeographyValue] = useState<string>(
    /all/gi.test(marketParam) ? (regionParam === "North America" ? "United States" : regionParam) : marketParam
  );

  const [APP_OPTIONS, setAppOptions] = useState(["All", "NissanConnect EV", servicesTitle]);
  const BRAND_OPTIONS = ["All", "Nissan", "Infiniti"];

  useEffect(() => {
    if (regionParamValue) {
      /all/gi.test(regionParamValue)
        ? setServicesTitle(allAppRatingsServicesTitle)
        : regionParamValue === "Europe"
        ? setServicesTitle(europeAppRatingsServicesTitle)
        : setServicesTitle(defaultAppRatingsServicesTitle);
    }
  }, [regionParamValue]);

  useEffect(() => setAppOptions(["All", "NissanConnect EV", servicesTitle]), [servicesTitle]);
  // Resets invalid ccs filter values
  useEffect(() => {
    dispatch(fetchAppRatingsFiltersData());
  }, [dispatch]);

  useEffect(() => {}, [regionParam]);

  const groupedDateRangeData = useMemo(() => {
    const groupedDate = groupByKeys(date_range, "year");
    return Object.keys(groupedDate).map((year) => ({ year, child: groupedDate[year] }));
  }, [date_range]);

  const appFilterOptions = useMemo(() => {
    if (brandParamValue && regionParamValue && region_apps) {
      const appsByRegion =
        regionParamValue == ALL_OPTION ? region_apps : region_apps.filter((item: any) => item.region == regionParamValue);
      const appByRegionBrand =
        brandParamValue == ALL_OPTION_NO_SPACE
          ? appsByRegion
          : appsByRegion.filter((item: any) => item.brand.toLowerCase() == brandParamValue.toLowerCase());

      const apps = new Set(appByRegionBrand.map((item: any) => item.application));

      return ["All", ...Array.from(apps)];
    }

    return ["All"];
  }, [region_apps, brandParamValue, regionParamValue]);

  // Updates date range filter value
  useEffect(() => {
    if (date_range) {
      // Todo: Updates date range values
      const selectedDateArray: Array<string> = dateRangeParamValue?.split(",");
      const availableDateOptions: Array<string> = date_range?.map(
        (dates: { year: number; month: string }) => `${dates.month} ${dates.year}`
      );

      const isFY20 = selectedDateArray.length === FY_TWENTY.length && selectedDateArray.every((dateValue) => FY_TWENTY.includes(dateValue));

      const checkConsecutive = checkConsecutiveMonths(selectedDateArray, availableDateOptions);

      const dateRangeInputValue = isFY20
        ? "FY20"
        : selectedDateArray.length > 1
        ? checkConsecutive.isConsecutive
          ? checkConsecutive.consecutiveDateRange
          : `${selectedDateArray?.length} months selected`
        : dateRangeParamValue;

      const isValidCCSDate: boolean = selectedDateArray.reduce(
        (result: boolean, date) => result && (date == ALL_OPTION_NO_SPACE ? true : availableDateOptions.includes(date)),
        true
      );

      const availableCalendarYears = Array.from(new Set(date_range.map((option: { month: string; year: number }) => option.year)));

      // Sets the default date selection to the latest available calendar year
      if (!isValidCCSDate) {
        const defaultSelection = availableDateOptions.filter((option) => {
          const calendarYearRe = new RegExp(`${availableCalendarYears[availableCalendarYears.length - 1]}`, "gi");

          return calendarYearRe.test(option);
        });

        dispatch(setSpecificParameter("date_range", defaultSelection.join(",")));
        dispatch(setSpecificParameter("dates", defaultSelection.join(",")));
      }
      setDateParam(dateRangeParamValue);
      setDateRangeFilterValue(dateRangeInputValue);
    }
  }, [dateRangeParamValue, date_range]);

  useEffect(() => {
    setRegionParam(regionParamValue);
  }, [regionParamValue]);

  useEffect(() => {
    setMarketParam(marketParamValue == "United States" ? marketParamValue : ALL_COUNTRIES);
  }, [marketParamValue]);

  // Set geography value
  useEffect(() => {
    setGeographyValue(/all/gi.test(marketParam) ? (regionParam === "North America" ? "United States" : regionParam) : marketParam);
  }, [regionParam, marketParam]);

  // Set app filter value
  useEffect(() => {
    const value = appParamValue
      ? appParamValue.includes("EV")
        ? "NissanConnect EV"
        : appParamValue == "Service"
        ? servicesTitle
        : appParamValue
      : ALL_OPTION_NO_SPACE;
    setAppValue(value);
  }, [appParamValue]);

  useEffect(() => {
    setBrandValue(brandParamValue);
  }, [brandParamValue]);

  // Set app store filter value
  useEffect(() => {
    const value = appStoreParamValue ? appStoreParamValue : ALL_OPTION_NO_SPACE;
    setAppStoreValue(value);
  }, [appStoreParamValue]);

  useEffect(() => {
    const value = appParamValue == "EV" ? "NissanConnect EV" : appParamValue == "Service" ? "NissanConnect Services" : appParamValue;
    if (!appFilterOptions.includes(value)) {
      dispatch(setSpecificParameter("app", ALL_OPTION_NO_SPACE));
    }
  }, [dispatch, appParamValue]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateParam);
    params.set("brand", brandParamValue);
    params.set("region", regionParam);
    params.set("market", marketParam);
    params.set("app_store", appStoreParamValue);
    params.set("app", appParamValue);
    params.set("topic", topicParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateParam, appParamValue, regionParam, marketParam, appStoreParamValue, brandParamValue, topicParamValue, history]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "CCS", page: location.pathname });

    const defaultParams = Cookies.get("default_params");

    defaultParams && history.push({ search: defaultParams });
    window.location.reload();
  }, [history, location]);

  const handleFilterOptionClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const filterName = dataset?.filter;
      const optionValue = dataset?.value;

      if (filterName && optionValue) {
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "CCS", page: "CCS App ratings" });
        if (filterName === "brand") {
          Promise.all([
            dispatch(setSpecificParameter(filterName, optionValue)),
            dispatch(setSpecificParameter("region", ALL_OPTION)),
            dispatch(setSpecificParameter("market", ALL_COUNTRIES)),
          ]);
        } else if (filterName === "app") {
          if (optionValue.includes("EV")) {
            dispatch(setSpecificParameter(filterName, "EV"));
          } else if (optionValue.includes("NissanConnect Services")) {
            dispatch(setSpecificParameter(filterName, "Service"));
          } else {
            dispatch(setSpecificParameter(filterName, optionValue));
          }
        } else {
          dispatch(setSpecificParameter(filterName, optionValue));
        }
      }
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    },
    [setShowFilterOptions, dispatch]
  );

  const handleMarketArrowClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { id: currentId, dataset },
      } = evt;
      const marketListId = dataset?.marketTarget;

      const currentElement = currentId ? document.getElementById(currentId) : undefined;
      const marketListElement = marketListId ? document.getElementById(marketListId) : undefined;
      const regionFilterUlElement = document.getElementById("region_ul");

      const shownClass = "show-market";
      const marketDownClass = "down";
      const regionMarketOpenClass = "market-open";

      const downClass = currentElement?.children[0]?.classList;
      if (!downClass?.contains(marketDownClass)) closeMarketOptions();
      downClass?.toggle(marketDownClass);
      marketListElement?.classList.toggle(shownClass);
      regionFilterUlElement?.classList.toggle(regionMarketOpenClass);
      setMarketOptionsVisible(!marketOptionsVisible);
    },
    [marketOptionsVisible]
  );

  const handleGeographyOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const regionValue = dataset?.region;
    const marketValue = dataset?.market;

    regionValue && dispatch(setSpecificParameter("region", regionValue));
    marketValue && dispatch(setSpecificParameter("market", marketValue));
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);

    eventTracking(MixpanelEvents.filter_change, { filter: "region", value: regionValue || "", dashboard: "CCS", page: "CCS App ratings" });
    eventTracking(MixpanelEvents.filter_change, { filter: "market", value: marketValue || "", dashboard: "CCS", page: "CCS App ratings" });
  }, []);

  const handleDateFilterSubmission = () => {
    const parameterName = "date_range";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => {
      if (!/^\d+$/.test(checkbox.value)) selectedValues.push(checkbox.value);
    });

    closeFilterOptions();
    const value = selectedValues.join(",");
    dispatch(setSpecificParameter(parameterName, value));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value, dashboard: "CCS", page: "CCS App ratings" });
  };

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/* Date range */}
        <MultiDateRangeWithPresets
          parentKey={"year"}
          childKey={"month"}
          filterName="DATE RANGE"
          // @ts-ignore
          filterList={groupedDateRangeData}
          value={dateRangeFilterValue}
          parameterName={"date_range"}
          parameterValue={dateRangeParamValue}
          onShowFilterOptions={showFilterOptionsFn}
          handleFilterSubmission={handleDateFilterSubmission}
        />

        {/* App */}
        <DefaultFilter
          list={BRAND_OPTIONS}
          filterName={"brand"}
          filterLabel={"BRAND"}
          filterValue={brandValue}
          handleFilterOptionClick={handleFilterOptionClick}
        />

        {/* Geography */}
        <CockpitGeographyFilter
          geographies={geographies}
          value={geographyValue}
          onHandleMarketArrowClick={handleMarketArrowClick}
          onShowFilterOptions={showFilterOptionsFn}
          onHandleGeographyOptionsClick={handleGeographyOptionClick}
        />
        {/* App */}
        <DefaultFilter
          list={appFilterOptions as Array<string>}
          filterName={"app"}
          filterLabel={"APP"}
          filterValue={appValue}
          handleFilterOptionClick={handleFilterOptionClick}
        />

        {/* App store */}
        <DefaultFilter
          list={APP_STORE_FILTER_OPTIONS}
          filterName={"app_store"}
          filterLabel={"OPERATING SYSTEM"}
          filterValue={appStoreValue}
          handleFilterOptionClick={handleFilterOptionClick}
        />

        {filterBtns.map((btn) => (
          <FilterBtns id={btn.id} navigateTo={btn.navigateTo} name={btn.name} key={btn.id} />
        ))}
      </>
    </FiltersLayout>
  );
});
