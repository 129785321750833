import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { BLACK, CCS_ANALYSIS_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { formatValueAxisUnits } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  chartId: string;
  showVolume: boolean;
  colorMapping: Record<string, string>;
  maxValue: number;
}

export class DISCarModelsChart extends Component<Props> {
  chartId = this.props.chartId;
  chart!: XYChart;
  colorMapping = {};

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, data, showVolume, colorMapping, maxValue } = this.props;

    this.chart = create(this.chartId, XYChart);
    this.chart.data = data;
    oceChartConfiguration(this.chart);
    this.chart.colors.list = CCS_ANALYSIS_COLORS.map((col) => color(col));
    this.chart.paddingBottom = 10;
    this.chart.paddingRight = 20;

    this.colorMapping = colorMapping;

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "model";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    const label = valueAxis.renderer.labels.template;
    label.wrap = false;
    label.truncate = false;
    // valueAxis.renderer.labels.template.rotation = -60;
    // valueAxis.renderer.labels.template.paddingRight = 10;
    // valueAxis.renderer.labels.template.paddingTop = -10;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    formatValueAxisUnits(valueAxis);
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + `${showVolume ? "" : "%"}`;
    });
    valueAxis.max = maxValue;

    const field = showVolume ? "volume" : "proportion";

    this.createSeries(field, "model", showVolume);
  }

  createSeries(field: string, name: string, showVolume: boolean) {
    const series = this.chart.series.push(new ColumnSeries());
    series.dataFields.categoryY = name;
    series.dataFields.valueX = field;
    series.columns.template.tooltipText = `[font-size: var(--regular_font_size);]{categoryY}: [bold; font-size: var(--regular_font_size);]{valueX.formatNumber('###,###')}${
      showVolume ? "" : "%"
    }[/]`;
    series.columns.template.height = percent(80);
    series.sequencedInterpolation = true;

    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }

    const colorMapping: any = this.colorMapping;

    series.columns.template.adapter.add("fill", function (fill, target) {
      const categoryY = target?.dataItem?.categories?.categoryY;
      if (categoryY) fill = colorMapping[categoryY];
      return fill;
    });

    series.columns.template.adapter.add("stroke", function (stroke, target) {
      const categoryY = target?.dataItem?.categories?.categoryY;
      if (categoryY) stroke = colorMapping[categoryY];
      return stroke;
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default DISCarModelsChart;
