import React from "react";
import { Tooltip } from "react-tippy";
import { NoDataMsg } from "../../../components/AppMessages";

interface Props {
  id: string;
  title: string;
  tooltipText?: string;
  data: Array<any>;
  isPercentage?: boolean;
}

const VarianceByChannelTable = (props: Props) => {
  const { id, title, tooltipText, data, isPercentage } = props;

  return (
    <div className="pf_tile">
      <div className="header">
        <h3>{title}</h3>
        {tooltipText && (
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={tooltipText}
            size={"small"}
          >
            <span className="info_tooltip"></span>
          </Tooltip>
        )}
      </div>
      {data?.length > 0 ? (
        <table className="variance-table">
          <thead>
            <tr>
              <th></th>
              {data.map((item: any) => (
                <th key={item.lead_source}>{item.lead_source}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>YoY</td>
              {data.map((item: any) => (
                <td key={`${item.lead_source}-${item.yoy}`} className={`${item.yoy > 0 ? "text-green" : item.yoy < 0 ? "text-red" : ""}`}>
                  {item.yoy > 0 ? "+" : ""}
                  {item.yoy}
                  {typeof item.yoy == "number" && isPercentage ? "%" : ""}
                </td>
              ))}
            </tr>
            <tr>
              <td>MoM</td>
              {data.map((item: any) => (
                <td key={`${item.lead_source}-${item.mom}`} className={`${item.mom > 0 ? "text-green" : item.mom < 0 ? "text-red" : ""}`}>
                  {item.mom > 0 ? "+" : ""}
                  {item.mom}
                  {typeof item.mom == "number" && isPercentage ? "%" : ""}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      ) : (
        <NoDataMsg />
      )}
    </div>
  );
};

export default VarianceByChannelTable;
