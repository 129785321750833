import { Dashboard } from "./interface";

export const MENU_ELEMENTS: Record<
  string,
  {
    id: string;
    name: string;
    description?: string;
    hide_on_mobile: boolean;
    has_children: boolean;
    children: Array<Dashboard>;
    hidden?: boolean;
    path?: string;
  }
> = {
  theme_mode: {
    id: "theme_mode",
    name: "THEME MODE",
    hide_on_mobile: false,
    has_children: true,
    children: [{ id: "theme_btn", name: "THEME", path: "", hide_on_mobile: false }],
  },
  try: {
    id: "try_button",
    name: "TRY",
    hide_on_mobile: false,
    has_children: true,
    children: [
      {
        id: "search_interest",
        name: "Search interest",
        path: "/search_interest",
        hide_on_mobile: true,
        overview_image: "search_interest",
        point_of_interest_summary:
          "Demand and Traffic Indicators: Market Level Customer Search Behaviour Data View of how 18 markets are searching for Nissan’s competitors",
        points_of_interest: [
          "View of car body type search and interest trends",
          "View of key events impacting search interest",
          "Competitor search interest trends",
        ],
      },
      {
        id: "seo_report",
        name: "SEO reporting",
        path: "/seo_dashboard",
        hide_on_mobile: false,
        overview_image: "seo_reporting",
        point_of_interest_summary: "Connecting Two Data Sets to Understand What Drives Traffic",
        points_of_interest: [
          "View of traffic from 19 markets including top traffic-driving queries",
          "View of how many customers landed on a Nissan website from organic search (visits)",
          "View of the number of clicks on a Nissan URL from Google Search results",
          "View the number of impressions (which is the number of times  a Nissan URL appears in a Google Search results page viewed by a user, not including paid Google Ads)",
        ],
      },
      {
        id: "online_ce_performance",
        name: "Online CE performance",
        path: "/digital_performance",
        hide_on_mobile: false,
        overview_image: "oce_performance",
        point_of_interest_summary: "Aggregate New Cars Online Performance Data at the Global Level",
        points_of_interest: [
          "View of customer online behaviours at global, regional and market level",
          "View customer behaviours with all nameplates within a region/market as well as focus on just one",
          "Understand which customer jobs are driving most engagement.",
          "View the number of visitors on PACE and conversation rates for those visitors",
        ],
      },
      {
        id: "CPO",
        name: "CPO Online CE performance",
        path: "/cpo_digital_performance",
        hide_on_mobile: false,
        overview_image: "cpo_dashboard",
        point_of_interest_summary: "Aggregate Used Cars Online Performance Data at the Global Level",
        points_of_interest: [
          "View of customer online behaviours at global, regional and market level",
          "View customer behaviours with all nameplates within a region/market as well as focus on just one",
          "Understand which customer jobs are driving most engagement.",
          "View the number of visitors on PACE and conversation rates for those visitors",
        ],
      },
      {
        id: "product_performance",
        name: "Product Performance",
        path: "/product_performance",
        hide_on_mobile: true,
        overview_image: "product_performance",
        point_of_interest_summary: "Product Performance at a global and market level based on the user journey on the website",
        points_of_interest: [
          "View of each product digital performance: visits, KBA and leads",
          "View of the influence on shopping tools and leads",
          "View of the customer experience",
          "View of the page performance, the bugs and error pages generated and the accessibitily",
        ],
      },
      {
        id: "voc",
        name: "VOC (Voice Of Customer)",
        path: "/voc/website",
        hide_on_mobile: false,
        overview_image: "voc",
        point_of_interest_summary: "Voice of customer data after filling out the feedback survey on the website",
        points_of_interest: [
          "View of experience rating broken down per market, month, device etc",
          "View of objective completion (how many people reached their objective while using the website) broken down per market, month, device etc",
          "View of rating per touchpoint",
          "View of verbatims classified into relevant topics to drive granular analysis on customer satisfaction",
        ],
      },
      {
        id: "journey_excellence",
        name: "Journey Excellence",
        path: "/journey_excellence",
        hide_on_mobile: false,
        // overview_image: "voc",
        // point_of_interest_summary: "Aggregate Multisource Data on a Rapid Growth Industry: Connected Car Services",
        // points_of_interest: [
        //   "View of engagement insights: app downloads, avg monthly users, connectivity status, enrollment",
        //   "View of revenue insights: connected business revenue, subscription and renewal ratio",
        //   "View of customer satisfaction insights: Nissan Connect apps, CCS subscription, CCS surveys, call center",
        //   "View of operational insights: data quality, top services",
        // ],
      },
      {
        id: "midas",
        name: "Marketing Investment Dashboard",
        path: "/midas/fmi_run_rate",
        hide_on_mobile: false,
        overview_image: "midas",
        point_of_interest_summary: "",
        points_of_interest: ["", "", "", ""],
      },
      {
        id: "car_configurator_g5",
        name: "Configurator explorer G5",
        path: "/beta/configurator-g5",
        hide_on_mobile: true,
        overview_image: "configurator_g5",
        point_of_interest_summary: "Data on How Users Interact with the Configurator",
        points_of_interest: [
          "Includes filters by model, market, and date",
          "Includes data on grade, color, engine, axle, transmission, equipment, fuel, and top options",
        ],
      },
      {
        id: "car_configurator_jp",
        name: "Configurator explorer JP",
        path: "/beta/car_configurator",
        hide_on_mobile: true,
        overview_image: "configurator_jp",
        point_of_interest_summary: "Data on How Users Interact with the Configurator",
        points_of_interest: [
          "Includes filters by model, market, and date",
          "Includes data on grade, color, engine, axle, transmission, equipment, fuel, and top options",
        ],
      },
      {
        id: "car_configurator_nibu",
        name: "Configurator explorer NIBU",
        path: "/beta/configurator-nibu",
        hide_on_mobile: true,
        overview_image: "configurator_jp",
        point_of_interest_summary: "Data on How Users Interact with the Configurator",
        points_of_interest: [
          "Includes filters by model, market, and date",
          "Includes data on grade, colour, engine, axle, transmission, equipment, fuel, and top options",
        ],
      },
    ],
  },
  buy: {
    id: "buy_button",
    name: "BUY",
    hide_on_mobile: false,
    has_children: true,
    children: [
      {
        id: "mtm_metrics_that_matter",
        name: "MTM metrics that matter",
        path: "/mtm_metrics",
        hide_on_mobile: false,
        overview_image: "mtm_metrics_that_matter",
        point_of_interest_summary: "Media Investment Impact Metrics on Future, In, and Near Market Data",
        points_of_interest: [
          "View of Near market insights: media investment, paid media impressions, reach, search interest",
          "View of Future market insights: cost per lead, digital leads. Digitally influenced close rate, sales",
          "View of Near market insights: media investment, visits, unique and returning visitors, KBA visits",
        ],
      },
      {
        id: "ecommerce",
        name: "Ecommerce",
        path: "/ecommerce",
        hide_on_mobile: true,
        overview_image: "ecommerce",
        point_of_interest_summary: "Ecommerce sales funnel deep dive starting from the visit to the online order",
        points_of_interest: [
          "View of the sales funnel: visit, qualified visit, checkout, online order, cancellation",
          "View of the influence on shopping tools conversion rate",
          "View of the region and model level online order trendline over the selected time period",
          "View of the T/DS and Non T/DS leads (online orders, contact dealers etc)",
        ],
      },
      {
        id: "cpo_operational",
        name: "CPO Operational Dashboard",
        path: "/cpo_operational",
        hide_on_mobile: false,
        overview_image: "digital_customer_experience",
        point_of_interest_summary: "",
        points_of_interest: [""],
      },
      {
        id: "product_marketing_optimization",
        name: "Product marketing optimization",
        path: "/product_marketing_optimization",
        hide_on_mobile: false,
        overview_image: "product_marketing_optimization",
        point_of_interest_summary:
          "Comparison between customer intention and actual sales volume at grade level, for a selection of models for G5 markets, Japan and the United States",
        points_of_interest: [
          "View the % difference between customer intention and sales at grade level",
          "View the sales price volume curve by model vs overall segment",
          "View the search interest by model",
        ],
      },
      {
        id: "brand_performance",
        name: "Brand Performance Dashboard",
        path: "/brand_performance",
        hide_on_mobile: false,
        overview_image: "product_marketing_optimization",
        point_of_interest_summary: "",
        points_of_interest: [],
      },
    ],
  },
  use: {
    id: "use_button",
    name: "USE",
    hide_on_mobile: false,
    has_children: true,
    children: [
      {
        id: "ccs_vital_signs",
        name: "CCS (Connected Car) vital signs",
        path: "/ccs_vital_signs/ccs_cockpit",
        hide_on_mobile: false,
        overview_image: "ccs_vital_signs",
        point_of_interest_summary: "Aggregate Multisource Data on a Rapid Growth Industry: Connected Car Services",
        points_of_interest: [
          "View of engagement insights: app downloads, avg monthly users, connectivity status, enrollment",
          "View of revenue insights: connected business revenue, subscription and renewal ratio",
          "View of customer satisfaction insights: Nissan Connect apps, CCS subscription, CCS surveys, call center",
          "View of operational insights: data quality, top services",
        ],
      },
      {
        id: "use_ownership",
        name: "USE Ownership",
        path: "/use_ownership/overview",
        hide_on_mobile: false,
        overview_image: "ccs_vital_signs",
        // point_of_interest_summary: "Aggregate Multisource Data on a Rapid Growth Industry: Connected Car Services",
        // points_of_interest: [
        //   "View of engagement insights: app downloads, avg monthly users, connectivity status, enrollment",
        //   "View of revenue insights: connected business revenue, subscription and renewal ratio",
        //   "View of customer satisfaction insights: Nissan Connect apps, CCS subscription, CCS surveys, call center",
        //   "View of operational insights: data quality, top services",
        // ],
      },
    ],
  },
  sales: {
    id: "sales_button",
    name: "Sales",
    hide_on_mobile: false,
    has_children: true,
    children: [
      {
        id: "purchase_funnel",
        name: "DIS",
        path: "/purchase_funnel_report",
        hide_on_mobile: true,
        overview_image: "purchase_funnel",
        point_of_interest_summary: "Trends on Mass Market Digitally Influenced Sales",
        points_of_interest: [
          "View of progress on digitally influenced sales for G1 markets",
          "View of sales and close rate by source compared to target sales and close rate",
          "View of sales and leads sources and trends",
          "View percentage of digitally influenced sales",
        ],
      },
      {
        id: "msrp",
        name: "MSRP",
        path: "/purchase_funnel_report/msrp",
        hide_on_mobile: true,
        overview_image: "msrp",
        point_of_interest_summary: "DIS and Non DIS sales price and volume at a car model level",
        points_of_interest: [
          "View of the MSRP added value generated through online sales vs offline sales",
          "View of the average MSRP selling price and sales volume, as well as the VME discount in percentage",
          "View of evolution of VME discount over the month",
          "View of a car model break down to compare their performances in a table and in a trendline graph",
        ],
      },
      {
        id: "executive_summary",
        name: "Executive Summary",
        path: "/executive_summary/dis",
        hide_on_mobile: true,
        overview_image: "msrp",
        // point_of_interest_summary: "DIS and Non DIS sales price and volume at a car model level",
        // points_of_interest: [
        //   "View of the MSRP added value generated through online sales vs offline sales",
        //   "View of the average MSRP selling price and sales volume, as well as the VME discount in percentage",
        //   "View of evolution of VME discount over the month",
        //   "View of a car model break down to compare their performances in a table and in a trendline graph",
        // ],
      },
    ],
  },
  archived: {
    id: "archived_button",
    name: "Archived",
    hide_on_mobile: false,
    has_children: true,
    children: [
      {
        id: "mobility_report",
        name: "C-19 mobility restrictions",
        path: "/mobility_report",
        hide_on_mobile: true,
        overview_image: "mobility_report",
        point_of_interest_summary: "A Tool to Understand User Mobility Compares to a Pre-pandemic Baseline",
        points_of_interest: [
          "Showcases changes in mobility by market which correlate with consumer confidence",
          "Mobility trends grouped by several categories: Retail and recreation, Transit stations, Workplace, Grocery and pharmacy, Parks, Residential",
        ],
      },
      {
        id: "digital_customer_experience",
        name: "Digital user experience",
        path: "/digital_user_experience",
        hide_on_mobile: true,
        overview_image: "digital_customer_experience",
        point_of_interest_summary: "User Experience Scores and Trends by Market",
        points_of_interest: [
          "View of engagement insights: Engagement, Frustration, Navigation, Technical, Form scores",
          "View of overall  customer experience: DXS",
        ],
      },
      {
        id: "online_to_physical_insights",
        name: "Online to physical insights",
        path: "/online_to_physical",
        hide_on_mobile: true,
        overview_image: "online_to_physical_insights",
        point_of_interest_summary: "Tracking the Relationship Between Online Behaviours and Sales",
        points_of_interest: [
          "View of cluster insights: total leads, total sales, days to sale, sales close %, days to lead, and number of visits",
          "View of lead quality scoring",
          "View of relative engagement by KBA type and relative form submission by lead type",
          "View of customer segments distribution",
        ],
      },
      {
        id: "ariya_experience_monitor",
        name: "ARIYA experience monitor",
        path: "/ariya/monitor.html",
        hide_on_mobile: false,
        overview_image: "ariya",
        point_of_interest_summary: "Real Time Data on Nissan’s First Global Vehicle Launch",
        points_of_interest: [
          "View of customer visits, sources, and trends for North America, Japan, and Europe",
          "View insights on Virtual Car and Vehicle Landing Page",
          "Understand handraiser insights of top versions, colours, and cities",
        ],
      },
      {
        id: "cxo_experiment_library",
        name: "CXO EXPERIMENT LIBRARY",
        path: "/beta/cxo_experiment",
        hide_on_mobile: true,
        point_of_interest_summary: "A Repository of Over 80 Data-led Experiments That Improve User Experience",
        points_of_interest: [
          "Demonstrates what works and what doesn’t work for users",
          "Illustrates the positive impact of the ‘test and learn’ methodology on business KPIs",
          "Includes recommendations for product, sales, and marketing teams",
        ],
      },
    ],
  },
  profile: {
    id: "user_profile",
    name: "USER NAME",
    hide_on_mobile: false,
    has_children: true,
    children: [
      { id: "admin", name: "ADMIN", path: "/admin", hide_on_mobile: false },
      { id: "info", name: "INFO", path: "/dashboard_info", hide_on_mobile: false },
      { id: "profile_preferences", name: "PROFILE", path: "/user_profile", hide_on_mobile: false },
      { id: "logout", name: "LOGOUT", path: "/logout", hide_on_mobile: false },
    ],
  },
};

export const ALL_PATHS: string[] = [
  "/home",
  "/login",
  "/logout",
  "/sign_up",
  "/forgotten_password",
  "/set-password",
  "/welcome",
  "/403",
  "/404",
  "/invalid_token",
  "/user_profile",
  "/digital_performance",
  "/cpo_digital_performance",
  "/data_info",
  "/dashboard_info",
  "/ccs_vital_signs/ccs_cockpit",
  "/ccs_vital_signs/app_ratings",
  "/ccs_vital_signs/pdca",
  "/ccs_vital_signs/data_quality",
  "/ccs_vital_signs/ccs_journey",
  "/ccs_vital_signs/ccs_journey/europe_activation",
  "/ccs_vital_signs/top_flop",
  "/ccs_vital_signs/ccs_analysis",
  "/data_info",
  "/dashboard_info",
  "/release_notes",
  "product_marketing_optimization",
  "/purchase_funnel",
  "/purchase_funnel/diagnostic",
  "/purchase_funnel/msrp",
  "/mobility_report",
  "/mobility_report/insights",
  "/online_to_physical",
  "/mtm_metrics",
  "/search_interest",
  "/digital_user_experience",
  "/digital_user_experience_core_models",
  "/beta/car_configurator",
  "/beta/configurator-g5",
  "/beta/configurator-nibu",
  "/admin",
  "/seo_dashboard",

  "/ariya/monitor",
  "/ariya/experience",
  "/ariya/handraisers/market-split",
  "/ariya/handraisers",
  "/ariya/preorder/japan",
  "/ariya/preorder/norway",
  "/ariya/preorder/united-states",
  "/ariya/preorder",
  "/midas",
  "/cpo_operational",
  "/product_performance",
  "/voc",
  "/ecommerce",
  "/journey_excellence",
  "/use_ownership",
  "brand_performance",
  "/executive_summary",
];
