import { ErrorBoundary } from "@sentry/react";
import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg } from "../../../components/AppMessages";
import JourneyExcellenceLineTrendChart from "../../../components/Charts/JourneyExcellence/JourneyExcellenceLineTrendChart";
import { ThemeContext } from "../../../context";

const ProductJourney = () => {
  const themeContext = useContext(ThemeContext);

  const { series, data } = useSelector((state: RootStateOrAny) => state.journey_excellence.performance_overview);
  const loading = useSelector((state: RootStateOrAny) => state.loading.performance_overview);

  return (
    <div id="product_journey" className="section-content journey-excellence-row" data-target="product_journey">
      <div className="chart_tile">
        <div className="tile-header">
          Website visit per product{" "}
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"Counts every time the site has at least one page fully loaded for the selected product"}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <div className="tile-content">
          <ErrorBoundary fallback={<ErrorMsg />}>
            <JourneyExcellenceLineTrendChart
              chartId="vocRatingTrendline"
              data={data}
              seriesList={series}
              theme={themeContext.theme}
              isLoading={loading}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default ProductJourney;
