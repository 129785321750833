// declare default language to use in .toLocaleString
export const DEFAULT_LANGUAGE = "en-UK";

export const DEFAULT_PRECISION = 2;
// format options for .toLocaleString
export const format_percent_options = {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: DEFAULT_PRECISION,
};

// format options for .toLocaleString
export const format_float_options = {
  minimumFractionDigits: 0,
  maximumFractionDigits: DEFAULT_PRECISION,
};

// format integers using .toLocaleString
export const format_integer_options = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const BIG_NUMBER_ABBREVIATION = [
  { number: 1e3, suffix: "K" },
  { number: 1e6, suffix: "M" },
  { number: 1e9, suffix: "B" },
];
