import { PieChart, PieSeries } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { legendConfiguration } from "../helpers/configurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  dataKey: string;
  isLoading: boolean;
  colors?: Array<string>;
}

class UsedCarsPieChart extends Component<Props> {
  chartId = this.props.chartId;
  chart!: PieChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, dataKey, colors } = this.props;

    this.chart = create(this.chartId, PieChart);
    this.chart.data = data;
    this.chart.fontFamily = "nissan";

    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.markers.template.width = 11.5;
    this.chart.legend.markers.template.height = 11.5;

    this.chart.legend.position = "right";
    this.chart.legend.valueLabels.template.disabled = true;
    this.chart.legend.marginTop = -40;
    this.chart.paddingBottom = 30;
    this.chart.hiddenState.properties.radius = percent(0);

    // Create Axes

    const series = this.chart.series.push(new PieSeries());
    series.dataFields.value = `total`;
    series.dataFields.category = `${dataKey}`;

    series.slices.template.strokeOpacity = 1;

    if (colors) {
      series.colors.list = colors.map((chartColor) => color(chartColor));
    }

    // This creates initial animation
    series.hiddenState.properties.opacity = 1;
    series.hiddenState.properties.endAngle = -90;
    series.hiddenState.properties.startAngle = -90;
    series.labels.template.disabled = true;
    series.ticks.template.disabled = true;
    const slice = series.slices.template;
    // slice.states.getKey("hover")!.properties.scale = 1;
    // slice.states.getKey("active")!.properties.shiftRadius = 0;
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default UsedCarsPieChart;
