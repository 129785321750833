import React, { Fragment, useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { closeFilterOptions, closeSubFilterOptions } from "../subs/helpers";

type FilterListInt = Array<{
  value: string;
  checked: boolean;
  indeterminate?: boolean;
  options?: Array<{ value: string; checked: boolean }>;
}>;

interface Props {
  filterName?: string;
  parentKey: string;
  filterList: FilterListInt;
  value: string;
  parameterName: string;
  parameterValue: string;
  onShowFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
}

export const DigitalLeadsSourcesFilter = (props: Props) => {
  const { filterName, parentKey, filterList, value, parameterValue, onShowFilterOptions, parameterName } = props;

  const dispatch = useDispatch();
  const { market: marketParamValue } = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters);
  const { lead_group: leadGroupParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const [filterOptionsState, setFilterOptionsState] = useState<FilterListInt>([]);

  useEffect(() => {
    setFilterOptionsState(filterList);
  }, [filterList]);

  useEffect(() => {
    if (parameterValue == ALL_OPTION_NO_SPACE && filterList.length > 0) {
      setFilterOptionsState((prevState) => [
        ...prevState.map((option) => ({
          ...option,
          checked: true,
          options: option.options?.map((childOption) => ({ ...childOption, checked: true })),
        })),
      ]);
    } else if (filterList.length > 0) {
      setFilterOptionsState((prevState) => [
        ...prevState.map((option) => {
          const allChildrenSelected = option.options?.every(
            (childOption) => leadGroupParamValue.includes(option.value) && parameterValue.includes(childOption.value)
          );
          return {
            ...option,
            checked: allChildrenSelected ? true : false,
            indeterminate: leadGroupParamValue.includes(option.value) && !allChildrenSelected,
            options: option.options?.map((childOption) => ({
              ...childOption,
              checked: leadGroupParamValue.includes(option.value) && parameterValue.includes(childOption.value),
            })),
          };
        }),
      ]);
    }
  }, [leadGroupParamValue, parameterValue, filterList]);

  const handleFilterSubmission = useCallback(() => {
    const allParentCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-relationship="child"]`
    );

    const allChildCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-relationship="child"]`
    );

    const selectedChildCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-relationship="child"]:checked`
    );

    const selectedLeadSources: Array<string> = [];
    const selectedLeadGroups: Set<string> = new Set();

    selectedChildCheckboxes?.forEach((childElement) => selectedLeadSources.push(childElement.value));
    selectedChildCheckboxes?.forEach((childElement) => selectedLeadGroups.add(childElement.dataset.parentValue as string));

    dispatch(
      setSpecificParameter(
        "leads_source",
        allChildCheckboxes.length == selectedChildCheckboxes.length ? "All" : selectedLeadSources.join(",")
      )
    );
    dispatch(
      setSpecificParameter(
        "lead_group",
        allParentCheckboxes.length == selectedLeadGroups.size ? "All" : Array.from(selectedLeadGroups).join(",")
      )
    );
    closeFilterOptions();
  }, [filterList]);

  const handleFilterApply = () => {
    closeSubFilterOptions();
    handleFilterSubmission();
  };

  const handleMultiSelection = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value, checked, dataset },
      } = evt;
      const parentValue = dataset?.parentValue;

      const allChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]`
      );
      const selectedChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]:checked`
      );

      const availableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]`
      );

      const selectedAvailableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
      );

      if (value === ALL_OPTION_NO_SPACE) {
        setFilterOptionsState((prevState) => [
          ...prevState.map((option) => {
            return {
              ...option,
              checked: checked,
              options: option.options?.map((option) => ({ ...option, checked: checked })),
            };
          }),
        ]);
        return;
      }

      if (marketParamValue == "Japan") {
        if (parentValue) {
          console;
          // handles selection of child options
          setFilterOptionsState((prevState) => [
            ...prevState.map((option) => {
              return {
                ...option,
                checked: parentValue == option.value && checked,
                options: option.options?.map((childOption) =>
                  value == childOption.value && parentValue == option.value ? { ...childOption, checked: checked } : { ...childOption }
                ),
              };
            }),
          ]);
        } else {
          //handles selection of parent options
          setFilterOptionsState((prevState) => [
            ...prevState.map((option) => {
              return {
                ...option,
                checked: option.value == value,
                options: option.options?.map((childOption) => ({
                  ...childOption,
                  checked: option.value == value,
                })),
              };
            }),
          ]);
        }
      } else {
        if (parentValue) {
          // handles selection of child options
          setFilterOptionsState((prevState) => [
            ...prevState.map((option) => {
              return {
                ...option,
                checked: option.value == parentValue ? selectedChildCheckbox.length == option?.options?.length : option.checked,
                indeterminate:
                  option.value == parentValue
                    ? selectedChildCheckbox.length > 0 && option?.options && selectedChildCheckbox.length < option?.options.length
                    : option.indeterminate,
                options: option.options?.map((childOption) =>
                  value == childOption.value && parentValue == option.value ? { ...childOption, checked: checked } : { ...childOption }
                ),
              };
            }),
          ]);
        } else {
          //handles selection of parent options
          setFilterOptionsState((prevState) => [
            ...prevState.map((option) => {
              return {
                ...option,
                checked: option.value == value ? checked : option.checked,
                options: option.options?.map((childOption) => ({
                  ...childOption,
                  checked: option.value == value ? checked : childOption.checked,
                })),
              };
            }),
          ]);
        }
      }
    },
    [filterOptionsState]
  );

  const renderOptions = useCallback(
    (options: FilterListInt, parentValue?: string) => {
      const handleMarketArrowClick = (evt: React.MouseEvent<HTMLElement>) => {
        const {
          currentTarget: { id: currentId, dataset },
        } = evt;
        const subCheckListId = dataset?.marketTarget;

        const arrowDownClass = "down";
        const showSubListClass = "show";

        const currentElement = currentId ? document.getElementById(currentId) : undefined;
        const targetUlElement = subCheckListId ? document.getElementById(subCheckListId) : undefined;

        currentElement?.classList.toggle(arrowDownClass);
        targetUlElement?.classList.toggle(showSubListClass);
      };

      return options.map((option) => {
        const showArrow = option.options && option.options?.length ? "show" : "hidden";

        return (
          <>
            <div className={"multiselect_container"}>
              <span
                id={parentValue ? `${parentKey}_${option.value}_${parentValue}_arrow` : `${parentKey}_${option.value}_arrow`}
                className={`arrow-span ${showArrow}`}
                data-market-target={`${option.value}_ul`}
                onClick={handleMarketArrowClick}
              >
                <span className={"market-arrow"} />
              </span>
              <input
                type="checkbox"
                className={"multiselect-checkbox"}
                id={parentValue ? `${parentKey}_${option.value}_${parentValue}` : `${parentKey}_${option.value}`}
                value={option.value}
                checked={option.checked}
                onChange={handleMultiSelection}
                data-parent-value={parentValue}
                data-filter-name={parameterName}
                data-options-type={!parentValue && option.value !== ALL_OPTION_NO_SPACE ? "single" : "grouped"}
                data-relationship={parentValue ? "child" : "parent"}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = option?.indeterminate ? true : false;
                  }
                }}
              />
              <label
                className="multiselect-label"
                htmlFor={parentValue ? `${parentKey}_${option.value}_${parentValue}` : `${parentKey}_${option.value}`}
              >
                {option.value}
              </label>
            </div>
            {option.options && (
              <ul className="multicheck_sub_filter_options" id={`${option.value}_ul`} data-parent-key={option.value}>
                {/* @ts-ignore */}
                {renderOptions(option.options, option.value)}
              </ul>
            )}
          </>
        );
      });
    },
    [filterOptionsState]
  );

  return (
    <Fragment>
      <div className="filter" id={`${filterName}_filter_div`}>
        <label className="filter_header" htmlFor={`${parentKey}_filter`}>
          {filterName}
        </label>

        <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${parentKey}_ul`}>
          <input readOnly type="text" id={`${parentKey}_filter`} className={"filter_input"} data-test-id={`${parentKey}`} value={value} />
          <span className="arrow_down " />
        </div>

        <ul className="filter_options" id={`${parentKey}_ul`} data-test-id="region_list">
          {renderOptions(filterOptionsState)}
          <div className="filter_options__submit_area">
            <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
              Apply
            </div>
          </div>
        </ul>
      </div>
    </Fragment>
  );
};
