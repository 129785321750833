import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchLmsDigitallyInfluencedSales, fetchLmsDisAndLeads, fetchPerformanceOverview } from "../../../actions/journeyExcellenceActions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import FmiTiles from "../tiles/FmiTiles";
import LeadsManagementSystem from "../tiles/LeadsManagementSystem";
import OnlineJourneyTiles from "../tiles/OnlineJourneyTiles";
import ProductJourney from "../tiles/ProductJourney";

const MainDashboardContent = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Journey Excellence", page: "Journey Excellence" });
  }, []);

  useEffect(() => {
    dispatch(fetchPerformanceOverview());
    dispatch(fetchLmsDisAndLeads());
    dispatch(fetchLmsDigitallyInfluencedSales());
  }, [history.location.search]);

  return (
    <div className="journey-excellence-main-content">
      <FmiTiles />
      <OnlineJourneyTiles />
      <LeadsManagementSystem />
      <ProductJourney />
    </div>
  );
});

export default MainDashboardContent;
