import { format } from "date-fns";
import React, { ReactNode, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import { getGranularityFromDate } from "../../../utils/utilityFunctions";

interface Props {
  title: string;
  mainValue: number | null;
  isPercentage?: boolean;
  subText?: string;
  yoyValue?: number | string;
  popValue?: number | string;
  lastThreeMonthsValue?: number;
  isLoading?: boolean;
  toggle?: ReactNode;
  showCurrency?: boolean;
  showSign?: boolean;
  ratingInverse?: boolean;
  ratingArrowInverse?: boolean;
  tooltipText: string;
  lastThreeMonthsStartDate?: string;
  lastThreeMonthsEndDate?: string;
}

const PoPLabelMapping: Record<string, string> = {
  monthly: "MoM",
  quarterly: "QoQ",
};

const DetailedValueTile = (props: Props) => {
  const {
    title,
    mainValue,
    isPercentage,
    subText,
    yoyValue,
    popValue,
    isLoading,
    showCurrency,
    showSign,
    ratingInverse,
    ratingArrowInverse,
    tooltipText,
    lastThreeMonthsValue,
    lastThreeMonthsStartDate,
    lastThreeMonthsEndDate,
  } = props;

  const currency = useSelector((state: RootStateOrAny) => state.journey_excellence.currency);
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam, "monthly");
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  return (
    <div className="detailed-tile">
      {isLoading ? (
        <LoadingEllipsis isLoading={isLoading} />
      ) : (
        <>
          <div className="metric-container">
            <div className="tile-header">
              <p>{title}</p>
              <Tooltip
                position="top"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={tooltipText}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <div className="tile-content">
              {mainValue ? (
                <div className="main-value">
                  {showSign ? (Number(mainValue) > 0 ? "+" : "-") : ""}
                  {showCurrency ? currency : ""}
                  {showSign ? Math.abs(mainValue).toLocaleString() : mainValue.toLocaleString()}
                  {isPercentage ? "%" : ""}
                </div>
              ) : (
                <div className="main-value">n/a</div>
              )}

              {subText ? <div className="sub-text">{subText}</div> : null}
            </div>
          </div>
          <div className="comparison-container">
            {yoyValue ? (
              <div className="sub-text comparison">
                <Ratings
                  value={Number(yoyValue) ?? "n/a"}
                  testValue={false}
                  isPercentageValue={false}
                  ratingIsInverse={ratingInverse}
                  arrowIsInverse={ratingArrowInverse}
                />
                {Number(yoyValue) > 0 ? "+" : ""}
                {yoyValue}
                {isPercentage ? "PTS" : "%"} YoY
              </div>
            ) : null}
            {popValue ? (
              <div className="sub-text comparison">
                <Ratings
                  value={Number(popValue) ?? "n/a"}
                  testValue={false}
                  isPercentageValue={false}
                  ratingIsInverse={ratingInverse}
                  arrowIsInverse={ratingArrowInverse}
                />
                {Number(popValue) > 0 ? "+" : ""}
                {popValue}
                {isPercentage ? "PTS" : "%"} {PoPLabel}
              </div>
            ) : null}
            <div className="sub-text comparison-subtext">
              {lastThreeMonthsValue
                ? `${lastThreeMonthsValue < 0 ? "-" : ""}${showCurrency ? currency : ""}${
                    showCurrency ? formatToThreeDigits(Math.abs(lastThreeMonthsValue)) : lastThreeMonthsValue.toLocaleString()
                  }${isPercentage ? "%" : ""}`
                : "n/a"}{" "}
              {`${lastThreeMonthsStartDate ? format(new Date(lastThreeMonthsStartDate), "LLL y") : ""} to ${
                lastThreeMonthsEndDate ? format(new Date(lastThreeMonthsEndDate), "LLL y") : ""
              } `}
              total
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailedValueTile;
