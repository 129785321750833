import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchConversionAndCost, fetchDigitalSpend, fetchDigitalSpendVisitsData } from "../../../actions/journeyExcellenceActions";
import { ToggleSwitch } from "../../../components/Toogle";
import ValueTile from "./ValueTile";

const FmiTiles = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const {
    Current: currentData,
    YoY: yoyData,
    PoP: popData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.digital_spend);
  const loading = useSelector((state: RootStateOrAny) => state.loading.digital_spend);

  const [toggleActive, setToggleActive] = useState(true);

  useEffect(() => {
    dispatch(fetchDigitalSpend(toggleActive));
    dispatch(fetchDigitalSpendVisitsData(toggleActive));
    dispatch(fetchConversionAndCost(toggleActive));
  }, [history.location.search, toggleActive]);

  return (
    <div id="fmi" className="section-content journey-excellence-row" data-target="fmi">
      <div className="section-toggle">
        <ToggleSwitch
          activeToggleLabel="Show only ACT"
          inactiveToggleLabel="Show ACT + BP"
          handleToggleClick={() => setToggleActive((prevState) => !prevState)}
          active={toggleActive}
          toggleClassName="toggle-justify-end"
        />
      </div>
      <div className="section-content-row">
        <ValueTile
          title="Digital spends"
          mainValue={currentData?.digital_spend}
          yoyValue={yoyData?.perc_digital_spend}
          popValue={popData?.perc_digital_spend}
          isLoading={loading}
          showCurrency={true}
          ratingInverse={true}
          ratingArrowInverse={true}
          tooltipText="Media FMI allocated through digital channels to reach its marketing goals or promote the brand messaging. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP."
        />
        <ValueTile
          title="Digital spend vs BP"
          mainValue={currentData?.digital_spend_vs_bp}
          yoyValue={yoyData?.perc_digital_spend_vs_bp}
          popValue={popData?.perc_digital_spend_vs_bp}
          isLoading={loading}
          showCurrency={true}
          showSign={true}
          ratingInverse={true}
          ratingArrowInverse={true}
          tooltipText="Comparison between planned and actual for digital spend. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP."
        />
        <ValueTile
          title="Digital spends share"
          mainValue={currentData?.digital_spend_share}
          yoyValue={yoyData?.perc_digital_spend_share}
          popValue={popData?.perc_digital_spend_share}
          isPercentage={true}
          isLoading={loading}
          ratingInverse={true}
          ratingArrowInverse={true}
          tooltipText="Percentage of the total Media Buy that was allocated to Digital Spend. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP."
        />
      </div>
    </div>
  );
});

export default FmiTiles;
