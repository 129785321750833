import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import "../../assets/styles/pages/cedarHome.scss";
import { ErrorMsg } from "../../components/AppMessages";
import { DashboardLayout } from "../../components/Layouts";
import { DARK_ACCENT, GRAY, MENU_ELEMENTS } from "../../constants";
import { ThemeContext } from "../../context";
import { DashBoardButtons } from "./components/dashBoardButtons";

export const CedarHome = () => {
  const { theme } = useContext(ThemeContext);

  //get all views from menuItems
  const tryDashBoards = MENU_ELEMENTS.try.children;
  const buyDashBoards = MENU_ELEMENTS.buy.children;
  const useDashBoards = MENU_ELEMENTS.use.children;
  const salesDashBoards = MENU_ELEMENTS.sales.children;
  const [activeBoard, setActiveBoard] = useState<string>("");

  return (
    <DashboardLayout>
      <ErrorBoundary fallback={<ErrorMsg class={"formError"} />}>
        <div className="main_dashboard">
          <h1 className="title">CEDAR Home overview</h1>
          <div className="main-cedar-dashboard">
            <div className="section">
              <div className="dashboard_group">
                <div className="group_title">
                  <SupportAgentIcon
                    fontSize="large"
                    className="group_icon"
                    sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, width: "100%", height: "100%" }}
                  />
                  <div className="title_description">
                    <h1 className="group_title_header">TRY</h1>
                    <p>Customer behaviour on Nissan website</p>
                  </div>
                </div>
                {tryDashBoards.map((dashboard: any) => (
                  <DashBoardButtons key={dashboard.id} dashboard={dashboard} activeBoard={activeBoard} setActiveBoard={setActiveBoard} />
                ))}
              </div>
            </div>
            <div className="section">
              <div className="dashboard_group">
                <div className="group_title">
                  <ShoppingCartIcon
                    fontSize="large"
                    className="group_icon"
                    sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, width: "100%", height: "100%" }}
                  />
                  <div className="title_description">
                    <h1 className="group_title_header">BUY</h1>
                    <p>Purchase data</p>
                  </div>
                </div>
                {buyDashBoards.map((dashboard: any) => (
                  <DashBoardButtons key={dashboard.id} dashboard={dashboard} activeBoard={activeBoard} setActiveBoard={setActiveBoard} />
                ))}
              </div>
            </div>
            <div className="section">
              <div className="dashboard_group">
                <div className="group_title">
                  <MinorCrashIcon
                    fontSize="large"
                    className="group_icon"
                    sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, width: "100%", height: "100%" }}
                  />
                  <div className="title_description">
                    <h1 className="group_title_header">USE</h1>
                    <p>Product and services use</p>
                  </div>
                </div>
                {useDashBoards.map((dashboard: any) => (
                  <DashBoardButtons key={dashboard.id} dashboard={dashboard} activeBoard={activeBoard} setActiveBoard={setActiveBoard} />
                ))}
              </div>
            </div>
            <div className="section">
              <div className="dashboard_group">
                <div className="group_title">
                  <TrendingUpIcon
                    fontSize="large"
                    className="group_icon"
                    sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, width: "100%", height: "100%" }}
                  />
                  <div className="title_description">
                    <h1 className="group_title_header">SALES</h1>
                    <p>Sales data</p>
                  </div>
                </div>
                {salesDashBoards.map((dashboard: any) => (
                  <DashBoardButtons key={dashboard.id} dashboard={dashboard} activeBoard={activeBoard} setActiveBoard={setActiveBoard} />
                ))}
              </div>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick={true}
            draggable={false}
            pauseOnHover={false}
          />
        </div>
      </ErrorBoundary>
    </DashboardLayout>
  );
};
