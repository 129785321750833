import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ALL_OPTION, VISITS_TABLE_COLUMN_HEADERS } from "../../constants";
import { trendNewData } from "../../constants/interface";
import { convertToAbsoluteFloat, toLocaleInteger } from "../../utils/utilityFunctions";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import Ratings from "../Ratings/Ratings";
import { DefaultTable, filterVisitsBreakdownTableData, filterVisitsTableData } from "./subs";

const VisitsTrendBreakdownTable = (props: {
  metric: string;
  breakdownColumnTitle: string;
  showPop: boolean;
  popLabel: string;
  isCpoDashboard?: boolean;
}) => {
  const { metric, breakdownColumnTitle, showPop, popLabel, isCpoDashboard } = props;

  const tableData: trendNewData = useSelector((state: RootStateOrAny) =>
    isCpoDashboard ? state.cpo_digital_performance.cpo_breakdown_trend_all : state.digital_performance.new_trend_breakdown.data
  );
  const regionParam: string = useSelector((state: RootStateOrAny) => state.parameters.region);
  const marketParam: string = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParam: string = useSelector((state: RootStateOrAny) => state.parameters.model);

  const topModels = useSelector((state: RootStateOrAny) => state.models.activeList);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.visits_breakdown_table);

  const hiddenCols = showPop ? ["breakdown"] : ["breakdown", "period_comparison"];
  const [breakdownSortId, setBreakdownSortId] = useState("visits");
  const [breakdownSortDesc, setBreakdownSortDesc] = useState(true);

  const dat = useMemo(() => {
    const data: any = [];
    let value;
    if (tableData?.YoY && tableData?.Current && tableData?.Current?.length) {
      if ("market" in tableData.Current[0] && tableData.Current.length === 1) {
        for (const Current of tableData.Current[0].break_down_data) {
          if (tableData.YoY && tableData.YoY.length) {
            for (const YoY of tableData.YoY[0].break_down_data) {
              if (Current.model === YoY.model) {
                Current.YoY = YoY;
              }
            }
          }
          if (tableData.PoP && tableData.PoP.length) {
            for (const WoW of tableData.PoP[0].break_down_data) {
              if (Current.model === WoW.model) {
                Current.WoW = WoW;
              }
            }
          }
          value = { Current };
          data.push(value);
        }
      } else {
        for (const Current of tableData.Current) {
          for (const YoY of tableData.YoY) {
            if ("region" in Current && Current.region === YoY.region) {
              Current.YoY = YoY;
            }
            if ("market" in Current && Current.market === YoY.market) {
              Current.YoY = YoY;
            }
          }
          if (tableData.PoP) {
            for (const WoW of tableData.PoP) {
              if ("region" in Current && Current.region === WoW.region) {
                Current.WoW = WoW;
              }
              if ("market" in Current && Current.market === WoW.market) {
                Current.WoW = WoW;
              }
            }
          }
          value = { Current };
          data.push(value);
        }
      }
    }
    return data;
  }, [tableData]);

  const data = useMemo(() => filterVisitsTableData(dat, metric), [dat, metric]);
  const breakDownAccessor = useMemo(() => {
    if (regionParam == ALL_OPTION && marketParam == "All countries") {
      return "Current.region";
    }
    if (regionParam != ALL_OPTION && marketParam == "All countries") {
      return "Current.market";
    }
    return "Current.model";
  }, [regionParam, marketParam, modelParam]);

  const breakDownAccessorCPO = useMemo(() => {
    if (regionParam == ALL_OPTION && marketParam == "All countries") {
      return "parent";
    }
    return "breakdown";
  }, [regionParam, marketParam, modelParam]);

  const columns = useMemo(
    () => [
      {
        id: "breakdowns",
        Header: breakdownColumnTitle,
        accessor: `${breakDownAccessor}`,
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (props: { row: { values: { breakdown: any }; getToggleRowExpandedProps: () => any }; cell: { row: { original: any } } }) => {
          const {
            row,
            cell: {
              row: { original },
            },
          } = props;

          const type =
            "model" in original || ("Current" in original && "model" in original["Current"])
              ? "model"
              : "market" in original
              ? "market"
              : "breakdown" in original
              ? "breakdown"
              : "region";

          const value =
            original?.["model"] ||
            original?.["market"] ||
            original?.["region"] ||
            original?.["parent"] ||
            original?.["breakdown"] ||
            original?.["Current"]?.["region"] ||
            original?.["Current"]?.["market"] ||
            original?.["Current"]?.["model"];

          const disable_click = type === "model" ? "disable_click" : "clickable";

          const spanProps = disable_click === "clickable" ? { ...row.getToggleRowExpandedProps() } : {};

          const parentValue = original?.parent ? original?.parent : "";
          return (
            <span
              className={`${disable_click} ${type === "model" || type === "market" || type === "breakdown" ? "filter-link" : ""}`}
              data-type={"market"}
              {...spanProps}
              data-value={value}
              data-parent={parentValue}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: VISITS_TABLE_COLUMN_HEADERS[metric],
        accessor: `Current.${metric}`,
        id: "visits",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",

        Cell: (props: { cell: { row: { original: any } } }) => {
          const {
            cell: {
              row: { original },
            },
          } = props;
          const value = original?.[metric] || original?.["Current"]?.[metric];
          return value ? toLocaleInteger(value) : "n/a";
        },
      },
      {
        id: "period_comparison",
        Header: popLabel,
        accessor: `Current.WoW.perc_${metric}`,
        sortType: "alphanumericFalsyLast",
        Cell: periodComparisonFormat,
      },
      {
        Header: "YoY",
        id: "year_comparison",
        accessor: `Current.YoY.perc_${metric}`,
        sortType: "alphanumericFalsyLast",
        Cell: yearComparisonFormat,
      },
      { Header: "Breakdown", accessor: "breakdown", show: false, Cell: ({ value }: { value: any }) => value[0].Parent },
    ],
    [breakdownColumnTitle, metric, topModels, popLabel, breakDownAccessor]
  );

  const setChildSortOrder = useCallback(
    (sortId: string, sortDesc: boolean) => {
      setBreakdownSortId(sortId);
      setBreakdownSortDesc(sortDesc);
    },
    [setBreakdownSortId, setBreakdownSortDesc]
  );

  function yearComparisonFormat(props: { cell: { row: { original: any } } }) {
    const {
      cell: {
        row: { original: current_val },
      },
    } = props;
    const currentValue = current_val?.["Current"]?.["YoY"]?.[`perc_${metric}`] || current_val?.["YoY"]?.[`perc_${metric}`];
    return (
      <span className="td_comparison">
        <Ratings value={currentValue ? (isNaN(currentValue) ? "na" : String(currentValue)) : ""} isPercentageValue={true} />{" "}
        {convertToAbsoluteFloat(currentValue, 0, true)}
      </span>
    );
  }

  function periodComparisonFormat(props: { cell: { row: { original: any } } }) {
    const {
      cell: {
        row: { original: current_val },
      },
    } = props;

    const currentValue = current_val?.["Current"]?.["WoW"]?.[`perc_${metric}`] || current_val?.["WoW"]?.[`perc_${metric}`];

    return (
      <span className="td_comparison">
        <Ratings value={currentValue ? (isNaN(currentValue) ? "na" : String(currentValue)) : ""} isPercentageValue={true} />{" "}
        {convertToAbsoluteFloat(currentValue, 0, true)}
      </span>
    );
  }

  const renderRowSubComponent = useCallback(
    // eslint-disable-next-line react/display-name
    () => (props: { row: { original: { Current?: any; breakdown?: any } } }) => {
      const {
        row: { original: data },
      } = props;

      const breakDownData: any = [];

      if (data?.Current?.break_down_data) {
        const yoy_b = data?.["Current"]?.["YoY"]?.break_down_data;
        const wow_b = data?.["Current"]?.["WoW"]?.break_down_data;

        for (const breakdown_current of data["Current"].break_down_data || []) {
          if (yoy_b) {
            for (const breakdown_yoy of yoy_b) {
              if ("market" in breakdown_current && breakdown_current.market === breakdown_yoy.market) {
                breakdown_current.YoY = breakdown_yoy;
              }
              if ("model" in breakdown_current && breakdown_current.model === breakdown_yoy.model) {
                breakdown_current.YoY = breakdown_yoy;
              }
            }
          }
          if (wow_b) {
            for (const breakdown_wow of wow_b) {
              if ("market" in breakdown_current && breakdown_current.market === breakdown_wow.market) {
                breakdown_current.WoW = breakdown_wow;
              }
              if ("model" in breakdown_current && breakdown_current.model === breakdown_wow.model) {
                breakdown_current.WoW = breakdown_wow;
              }
            }
          }

          breakDownData.push(breakdown_current);
        }
      }

      const formattedBreakdownData = filterVisitsBreakdownTableData(breakDownData, metric, topModels, {
        region: regionParam,
        market: marketParam,
        model: modelParam,
      });

      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          {formattedBreakdownData?.length > 0 ? (
            <DefaultTable
              columns={columns}
              data={formattedBreakdownData}
              id={"visits_trend_breakdown_child"}
              //@ts-ignore
              isChildComponent={true}
              initialSortColId={{ id: breakdownSortId, desc: !breakdownSortDesc }}
              hiddenCols={hiddenCols}
            />
          ) : (
            <NoDataMsg />
          )}
        </ErrorBoundary>
      );
    },
    [metric, columns, hiddenCols, breakdownSortDesc, breakdownSortId, marketParam, modelParam, regionParam, topModels]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {data && data.length > 0 ? (
          <>
            <DefaultTable
              columns={columns}
              data={data}
              id={"visits_trend_breakdown"}
              data-test-id={"visits_trend_breakdown"}
              // @ts-ignore
              renderRowSubComponent={renderRowSubComponent()}
              isChildComponent={false}
              initialSortColId={{ id: "visits", desc: true }}
              hiddenCols={hiddenCols}
              setSortInformation={setChildSortOrder}
            />
          </>
        ) : isLoading ? null : (
          <NoDataMsg />
        )}
      </>
    </ErrorBoundary>
  );
};

export default VisitsTrendBreakdownTable;
