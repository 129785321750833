import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getExecutiveSummaryDataDefinitions } from "../../../api/executiveSummaryWebsiteApi";

const ExecutiveSummaryData = () => {
  const [dataUpdates, setDataUpdates] = useState<Array<any>>();

  useEffect(() => {
    const page = location.pathname?.split("/")?.at(-1)?.replaceAll("_", "-");

    getExecutiveSummaryDataDefinitions(page as string).then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setDataUpdates(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>EXECUTIVE SUMMARY - DATA OVERVIEW</h3>
      <h4>Executive Summary KPI definitions</h4>
      <br />
      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>KPI definition</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.KPI}</td>
              <td>{data.KPI_definition}</td>
              <td>{data.Source}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExecutiveSummaryData;
