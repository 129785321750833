import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BIG_NUMBER_ABBREVIATION, BLACK, BLUE, BRIGHT_BLUE, GRAY, RED, TURQUOISE, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  chartName: string;
  isLoading: boolean;
  theme: ThemeInt;
  seriesList: Array<Record<string, string>>;
}

const BAR_COLORS = [TURQUOISE, GRAY, BRIGHT_BLUE, BLUE, RED];
class DigitalLeadsSourceChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipConfig = this.tooltipConfig.bind(this);
  }

  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }
  initChart() {
    const { data, seriesList, theme } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.legend.reverseOrder = false;

    // Create current period date chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 20;
    dateAxis.dateFormats.setKey("month", "MMM");
    // dateAxis.periodChangeDateFormats.setKey("month", "MMM yy");

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.numberFormatter.numberFormat = "#.a";
    valueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;

    const lineValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(lineValueAxis, theme);
    lineValueAxis.renderer.numberFormatter.numberFormat = "#.a";
    lineValueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
    lineValueAxis.renderer.opposite = true;
    lineValueAxis.syncWithAxis = valueAxis;
    lineValueAxis.min = 0;
    lineValueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    seriesList
      .filter((series) => series.type == "bar")
      ?.forEach(({ name, field, color: seriesColor }, idx) => {
        const series = this.chart.series.push(new ColumnSeries());
        series.dataFields.dateX = "date";
        series.name = name;
        series.stacked = true;
        series.fill = seriesColor ? color(seriesColor) : color(BAR_COLORS[idx]);
        series.stroke = seriesColor ? color(seriesColor) : color(BAR_COLORS[idx]);
        series.dataFields.valueY = field;
        series.xAxis = dateAxis;
        series.numberFormatter = new NumberFormatter();
        series.columns.template.tooltipText = "test";
        series.columns.template.adapter.add("tooltipText", this.tooltipConfig);
        if (series.tooltip) {
          series.tooltip.getFillFromObject = false;
          series.tooltip.autoTextColor = false;
          series.tooltip.background.fill = color(WHITE);
          series.tooltip.label.fill = color(BLACK);
          series.tooltip.fontSize = "var(--regular_font_size)";
        }
      });

    seriesList
      .filter((series) => series.type == "line")
      ?.forEach(({ name, field, color: seriesColor }, idx) => {
        const series = this.chart.series.push(new LineSeries());
        series.dataFields.dateX = "date";
        series.fill = theme == "dark" ? color(WHITE) : color(BLACK);
        series.stroke = theme == "dark" ? color(WHITE) : color(BLACK);
        const circleBullet = lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
          yAxis: lineValueAxis,
        });
        circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{name}:[bold font-size: var(--regular_font_size)] {valueY.formatNumber('###,###')}`;
        this.chart.series.each((series) => {
          series.bullets.getIndex(0)?.adapter.add("tooltipText", this.tooltipConfig);
        });
        if (series.tooltip) {
          series.tooltip.fontSize = "var(--regular_font_size)";
        }
      });
  }

  tooltipConfig(value: string | undefined, target: Sprite) {
    const dataValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

    let text = `[bold]${formatDateAxisLabelToMonthly(dataValue?.date)}[/]\n`;

    this.chart.series.each((item) => {
      const value = item?.dataFields?.valueY && dataValue ? (dataValue[item.dataFields.valueY] as number) : null;
      text += `[${item.stroke}]●[/] ${item.name}: ${value ? Number(value).toLocaleString() : "n/a"}\n`;
    });

    return text;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default DigitalLeadsSourceChart;
