import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchMarketDataRefresh,
  fetchOCEAllMarketRegions,
  fetchOCEAllRegions,
  fetchOCEChannelsByBrandFilters,
  fetchOCEMarketsByBrandFilters,
  fetchOCEModelsByBrandFilters,
  setAvailableMarketingChannels,
  setHasAriya,
  setHasAura,
  setHasQashqai,
  setHasSakura,
  setModels,
  setOptionsList,
  setParameters,
  setSpecificParameter,
} from "../../actions";
import "../../assets/styles/component/filters.scss";
import {
  ALL_COUNTRIES,
  ALL_OPTION,
  ALL_OPTION_NO_SPACE,
  ARIYA,
  AURA,
  DEFAULT_DATE_RANGE,
  DEFAULT_OCE_PARAMETERS,
  DEFAULT_PARAMETER_VALUES,
  MIN_SELECTABLE_DATE,
  NISSAN,
  OCE_DATE_FILTER_OPTIONS,
  QASHQAI_2021,
  SAKURA,
} from "../../constants";
import { checkFormattedGeographyData, ModelDataList } from "../../constants/interface";
import { FilterContext } from "../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet, isNull } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { ChannelFilter, DateFilter, DefaultFilter, ModelFilter, NewGeographyFilter } from "./common";
import { closeFilterOptions, closeMarketOptions, objectToQuerystring } from "./subs/helpers";

export const DigitalPerformanceFilters = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const dispatch = useDispatch();

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.digital_performance);
  const marketDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.market_specific_data_dates);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const regionParamValue = useSelector((state: RootStateOrAny) => state.parameters.region);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.parameters.model);
  const channelParamValue = useSelector((state: RootStateOrAny) => state.parameters.last_touch_marketing_channel);
  const channelBreakdownParamValue = useSelector((state: RootStateOrAny) => state.parameters.last_touch_marketing_channel_breakdown);

  const {
    filterOptions: { geography: geographies },
  } = useSelector((state: RootStateOrAny) => state.filters);
  const { data: modelData, hasAriya, hasQashqai, hasAura } = useSelector((state: RootStateOrAny) => state.models);
  const { list: availableChannels } = useSelector((state: RootStateOrAny) => state.marketing_channels);

  const models = useSelector((state: RootStateOrAny) => state.filters.oce_model_by_brand_filters);
  const allRegions = useSelector((state: RootStateOrAny) => state.filters.oce_all_regions);
  const allChannels = useSelector((state: RootStateOrAny) => state.filters.oce_channels_by_brand_filters);
  const allMarkets = useSelector((state: RootStateOrAny) => state.filters.oce_markets_by_brand_filters);

  const [regionCountryValue, setRegionCountryValue] = useState(regionParamValue);
  const [channelValue, setChannelValue] = useState(channelParamValue);
  const [testedGeographies, setTestedGeographies] = useState<checkFormattedGeographyData>([]);
  const [brands, setBrands] = useState<string[]>([]);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Online CE performance");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_OCE_PARAMETERS));
      currentDefaultFiltersSet("Online CE performance");
    }
  }, []);

  useEffect(() => {
    Promise.all([
      dispatch(fetchMarketDataRefresh()),
      dispatch(fetchOCEAllRegions()),
      dispatch(fetchOCEModelsByBrandFilters()),
      dispatch(fetchOCEMarketsByBrandFilters()),
      dispatch(fetchOCEChannelsByBrandFilters()),
      dispatch(fetchOCEAllMarketRegions()),
    ]);
  }, [dispatch]);

  useEffect(() => {
    const brands = Object.keys(allMarkets);
    if (brandParamValue === ALL_OPTION_NO_SPACE && brands && brands.length) dispatch(setSpecificParameter("brand", brands[0]));
    setBrands(brands);
  }, [brandParamValue, allMarkets, dispatch]);

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: dataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  const allRegionsForBrand = useMemo(() => {
    if (brandParamValue && Object.keys(allRegions).length) {
      return allRegions
        ?.filter((row: { brand: string }) => row.brand === brandParamValue)
        ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));
    }
    return [];
  }, [brandParamValue, allRegions]);

  const geos = useMemo(() => {
    const markets = allMarkets[brandParamValue];
    return allRegionsForBrand?.map((val: { name: any; sub_regions: string[] }) => {
      const subGeo: any[] = [];
      const subGeoMarketSPlit: any[] = [];
      const marketsForRegion = markets?.[val?.name == "ASEAN/Japan" ? "ASEAN" : val?.name] || [];

      if (val?.sub_regions?.length) {
        val?.sub_regions?.map((sub_region) => {
          const sub_geo = { region: val?.name, checked: true, indeterminate: false, markets: markets?.[sub_region] || [] };
          subGeo.push(sub_geo);
          const sub_geo_market_split = { region: sub_region, markets: markets?.[sub_region] || [] };
          subGeoMarketSPlit.push(sub_geo_market_split);
        });
      }

      const res = {
        region: val?.name == "ASEAN" ? "ASEAN/Japan" : val?.name,
        has_sub_region: Boolean(val?.sub_regions?.length),
        checked: false,
        indeterminate: false,
        subGeo: subGeo,
        markets: marketsForRegion?.sort((a: string, b: string) => a.trim().localeCompare(b.trim())),
        market_split: val?.sub_regions?.length
          ? subGeoMarketSPlit
          : [{ region: "All", markets: [markets?.[val?.name == "ASEAN/Japan" ? "ASEAN" : val?.name][0]] }],
      };
      return res;
    });
  }, [allRegionsForBrand, allMarkets, brandParamValue, regionCountryValue]);

  const modelList: string[] = useMemo(() => {
    if (Object.keys(models).length && brandParamValue && regionCountryValue) {
      const modelsForBrand: string[] = [];
      if (/all/gi?.test(regionParamValue)) {
        const metric = /all/gi.test(marketParamValue)
          ? allRegionsForBrand?.map((val: { name: any }) => val?.name)
          : marketParamValue?.split(",");

        metric?.map((region: string) => {
          if ((models[brandParamValue] && region in models[brandParamValue]) || region === "ASEAN/Japan") {
            modelsForBrand.push(...models[brandParamValue][region === "ASEAN/Japan" ? "ASEAN" : region]);
          }
        });
      } else {
        const regions = regionParamValue?.split(",");
        regions?.map((region: string) => {
          modelsForBrand.push(...models[brandParamValue][region === "ASEAN/Japan" ? "ASEAN" : region]);
        });
      }
      return ["All", ...Array.from(new Set(modelsForBrand))];
    }
    return [];
  }, [regionParamValue, marketParamValue, models, brandParamValue, allRegionsForBrand, marketParamValue]);

  const channelList = useMemo(() => {
    if (Object.keys(allChannels).length && brandParamValue && regionCountryValue) {
      const channelsForBrand: any[] = [];
      if (/all/gi?.test(regionParamValue)) {
        const metric = /all/gi.test(marketParamValue)
          ? allRegionsForBrand?.map((val: { name: any }) => val?.name)
          : marketParamValue?.split(",");
        metric?.map((region: string) => {
          if ((allChannels[brandParamValue] && region in allChannels[brandParamValue]) || region === "ASEAN/Japan") {
            channelsForBrand.push(allChannels[brandParamValue][region === "ASEAN/Japan" ? "ASEAN" : region]);
          }
        });
      } else {
        const regions = regionParamValue?.split(",");
        regions?.map((region: string) => {
          channelsForBrand.push(
            allChannels[brandParamValue][region === "ASEAN/Japan" ? "ASEAN" : region == "NAE" || region == "AMIO" ? "AMIEO" : region]
          );
        });
      }
      const keys: any = [];

      if (channelsForBrand?.length) channelsForBrand?.forEach((channel) => (channel ? keys.push(...Object.keys(channel)) : null));
      const uniqueKeys: string[] = Array.from(new Set(keys));
      const res: any = {};
      channelsForBrand?.map((val) => {
        uniqueKeys?.map((key) => {
          if (`${key}` in res && res[`${key}`]) {
            const data = [...res[`${key}`]];
            `${key}` in val && data.push(...val[`${key}`]);
            res[`${key}`] = Array.from(new Set(data));
          } else {
            res[`${key}`] = val[`${key}`];
          }
        });
      });
      const channels: { channel: string; breakdown: string[] }[] = [{ channel: "All", breakdown: [] }];
      uniqueKeys?.map((val: string) => {
        const result = { channel: val, breakdown: res[`${val}`] };
        channels.push(result);
      });

      return channels;
    }
    return [];
  }, [allChannels, brandParamValue, regionParamValue, marketParamValue, allRegionsForBrand, regionCountryValue]);

  // Set default date range values
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate: boolean = isDigitalPerformanceDate(dateRangeParamValue) || isCustomDate(dateRangeParamValue);

      if (!isValidDate) dispatch(setSpecificParameter("date_range", DEFAULT_DATE_RANGE));
    }
  }, [dateRangeParamValue]);

  // Resets japan region filter
  useEffect(() => {
    if (regionParamValue) {
      regionParamValue === "Japan" &&
        Promise.all([dispatch(setSpecificParameter("region", ALL_OPTION)), dispatch(setSpecificParameter("market", regionParamValue))]);

      regionParamValue === "North America" &&
        Promise.all([dispatch(setSpecificParameter("region", "Americas")), dispatch(setSpecificParameter("market", ALL_COUNTRIES))]);
    }
  }, [regionParamValue]);

  // Sets url on parameter change
  useEffect(() => {
    if (Object.keys(parameters)?.length > 0) {
      history.push({ search: `?${objectToQuerystring(parameters)}` });

      dispatch(fetchMarketDataRefresh()); //Fetch market specific data refresh when filter params change
    }
  }, [parameters]);

  //Update marketDataAvailability when value when market value is updated
  useEffect(() => {
    if (marketDataRefresh && marketParamValue in marketDataRefresh) {
      const selectedMarket = marketDataRefresh[marketParamValue][0];

      if (selectedMarket) {
        const selectedStart = moment(selectedMarket.start_date, "YYYY-MM-DD").isSameOrBefore(moment(MIN_SELECTABLE_DATE, "DD/MM/YYYY"))
          ? MIN_SELECTABLE_DATE
          : moment(selectedMarket.start_date, "YYYY-MM-DD").format("DD/MM/YYYY");

        setMarketDataAvailability({ start: selectedStart, end: moment(selectedMarket.end_date, "YYYY-MM-DD").format("DD/MM/YYYY") });
      }
    }
  }, [marketDataRefresh, marketParamValue]);

  // Sets available group and geography options
  useEffect(() => {
    const geographyList = [{ region: ALL_OPTION }, ...geos];
    dispatch(setOptionsList(geographyList, "geography"));
  }, [geos, brandParamValue]);

  //Set geography input value
  useEffect(() => {
    if (geos && geos?.length > 0) {
      const marketAllRe = new RegExp(ALL_COUNTRIES, "gi");

      const geographyValue = !marketAllRe.test(marketParamValue)
        ? marketParamValue.includes(",")
          ? `${marketParamValue.split(",")?.length} markets selected`
          : marketParamValue
        : regionParamValue.includes(",")
        ? regionParamValue.split(",")?.length === geographies?.length - 1
          ? ALL_OPTION
          : `${regionParamValue.split(",")?.length} regions selected`
        : regionParamValue;
      setRegionCountryValue(geographyValue);

      const isMultiRegion = /regions/gi.test(geographyValue);
      const isMultiMarket = /markets/gi.test(geographyValue);

      const updatesGeographies = geoFormatting(geographies, isMultiRegion, isMultiMarket, geographyValue);
      //@ts-ignore
      setTestedGeographies(updatesGeographies);
    }
  }, [geos, geographies, regionParamValue, marketParamValue]);

  // Sets model filters list
  useEffect(() => {
    if (!isNull(modelData) && regionCountryValue) {
      const geoValue = regionCountryValue === "ASEAN/Japan" ? "ASEAN" : regionCountryValue;
      const multipleSelectionRe = new RegExp("selected", "gi");
      // If multiple regions/ markets are selected disable model Selection
      const isMultiValue = multipleSelectionRe.test(geoValue);
      const regionSelector = geoValue === ALL_OPTION || isMultiValue ? "Global" : geoValue;
      const modelList: ModelDataList = geoValue === ALL_OPTION || isMultiValue ? [] : modelData?.[brandParamValue]?.[regionSelector];

      const modelsArr = modelList?.map((row) => row.model);

      const ariyaRe = new RegExp("ariya", "gi");
      const qashqai2021Re = new RegExp("Qashqai 2021", "gi");
      const auraRe = new RegExp("Aura", "gi");
      const sakuraRe = new RegExp("Sakura", "gi");

      dispatch(setHasAriya(false));
      dispatch(setHasQashqai(false));
      dispatch(setHasAura(false));
      dispatch(setHasSakura(false));

      modelsArr?.forEach((model: string) => {
        if (ariyaRe.test(model)) dispatch(setHasAriya(true));
        if (qashqai2021Re.test(model)) dispatch(setHasQashqai(true));
        if (auraRe.test(model)) dispatch(setHasAura(true));
        if (sakuraRe.test(model)) dispatch(setHasSakura(true));
      });

      const topModelList = modelsArr?.slice(0, 10);
      if (hasAriya && topModelList && !topModelList.includes(ARIYA)) topModelList.push(ARIYA);
      if (hasQashqai && topModelList && !topModelList.includes(QASHQAI_2021)) topModelList.push(QASHQAI_2021);
      if (hasAura && topModelList && !topModelList.includes(AURA) && geoValue === "Japan") topModelList.push(AURA);
      if (hasAura && topModelList && !topModelList.includes(SAKURA) && geoValue === "Japan") topModelList.push(SAKURA);
      if (typeof topModelList === "undefined") return;

      dispatch(setModels(topModelList));
      const modelFilterList = topModelList?.length >= 0 ? [ALL_OPTION_NO_SPACE, ...topModelList] : [ALL_OPTION_NO_SPACE];
      dispatch(setOptionsList(modelFilterList, "model"));
    }
  }, [brandParamValue, modelParamValue, regionCountryValue, modelData, hasAriya, hasQashqai, hasAura]);

  // reset model filter// reset model filter
  useEffect(() => {
    if ((modelList && modelParamValue === "ARIYA") || (modelParamValue === "Qashqai 2021" && modelList.includes(modelParamValue))) return;
    if (
      modelList &&
      !modelList.includes(modelParamValue) &&
      modelList.includes("ARIYA") &&
      modelList.includes("Qashqai 2021") &&
      modelList.length <= 3
    )
      return;
    if (modelList && !modelList.includes(modelParamValue) && modelList.includes("ARIYA") && modelList.length <= 2) return;
    if (modelList && modelList.length > 3 && modelList.includes(modelParamValue)) return;
    if (modelList && modelList.length > 1 && !modelList.includes(modelParamValue))
      dispatch(setSpecificParameter("model", ALL_OPTION_NO_SPACE));
  }, [regionCountryValue, modelParamValue, modelList]);

  // Formatting channelData
  useEffect(() => {
    if (allChannels) {
      const availableChannels = channelList?.reduce((result: string[], { channel, breakdown }) => {
        return [...result, channel, ...breakdown];
      }, []);

      dispatch(setOptionsList(channelList, "channel"));
      dispatch(setAvailableMarketingChannels(availableChannels));
    }
  }, [marketParamValue, channelList]);

  // Set channel input value
  useEffect(() => {
    const channelInputValue = channelBreakdownParamValue !== ALL_OPTION ? channelBreakdownParamValue : channelParamValue;
    setChannelValue(channelInputValue);
  }, [channelParamValue, channelBreakdownParamValue]);

  // Reset channel on options change
  useEffect(() => {
    if (availableChannels?.length > 1) {
      if (!availableChannels?.includes(channelValue)) {
        dispatch(setSpecificParameter("last_touch_marketing_channel", ALL_OPTION));
        dispatch(setSpecificParameter("last_touch_marketing_channel_breakdown", ALL_OPTION));
      }
    }
  }, [availableChannels, channelValue]);

  useEffect(() => {
    if (/datsun/gi.test(brandParamValue)) dispatch(setSpecificParameter("brand", NISSAN));
  }, [brandParamValue]);

  const subGeoFormatting = (
    subGeo: { region: string; markets: Array<string> }[],
    parent: string,
    isMultiRegion: boolean,
    isMultiMarket: boolean,
    geographyValue: string
  ) => {
    return subGeo.map((geo) => {
      const { region, markets } = geo;

      let result = { ...geo, checked: false, indeterminate: false };
      if (geographyValue === ALL_OPTION) {
        if (!isMultiMarket) {
          result = { ...geo, indeterminate: false, checked: true };
        }
      } else {
        if (geographyValue === parent || geographyValue === region || regionParamValue.split(",").includes(parent)) {
          result = { ...result, checked: true };
        } else if (isMultiMarket) {
          const selectedMarkets = marketParamValue.split(",").filter((val: string) => markets?.includes(val));
          const isIndeterminate = markets && selectedMarkets?.length > 0 && selectedMarkets?.length < markets?.length;
          const isChecked = markets && selectedMarkets?.length === markets?.length;

          result = { ...geo, checked: isChecked, indeterminate: isIndeterminate };
        } else if (markets?.includes(geographyValue)) {
          result = { ...geo, checked: false, indeterminate: true };
        }
      }
      return result;
    });
  };

  const geoFormatting = (
    geos: { region: string; markets?: string[]; has_sub_region?: boolean; market_split?: { region: string; markets: string[] }[] }[],
    isMultiRegion: boolean,
    isMultiMarket: boolean,
    geographyValue: string
  ) => {
    return geos.map((geo) => {
      const { region, markets, has_sub_region, market_split } = geo;

      let result: Record<string, unknown>;

      if (region === ALL_OPTION) {
        if (isMultiMarket) {
          result = { ...geo, indeterminate: true, checked: false };
        } else if (geographyValue === ALL_OPTION) {
          result = { ...geo, indeterminate: false, checked: true };
        } else {
          result = { ...geo, indeterminate: true, checked: false };
        }
      } else {
        if (isMultiRegion) {
          result = { ...geo, indeterminate: false, checked: regionParamValue.split(",").includes(region) };
        } else if (isMultiMarket) {
          const selectedMarkets = marketParamValue.split(",").filter((val: string) => markets?.includes(val));
          const isIndeterminate = markets && selectedMarkets?.length > 0 && selectedMarkets?.length < markets?.length;
          const isChecked = markets && selectedMarkets?.length === markets?.length;

          result = { ...geo, checked: isChecked, indeterminate: isIndeterminate };
        } else {
          const isIndeterminate = markets?.includes(geographyValue);
          result = {
            ...geo,
            checked: geographyValue === ALL_OPTION || region === geographyValue,
            indeterminate: isIndeterminate,
          };
        }
      }

      if (has_sub_region && market_split) {
        const formattedSubRegion = subGeoFormatting(market_split, region, isMultiRegion, isMultiMarket, geographyValue);
        const selectedSubRegions = formattedSubRegion.filter((subRegion) => subRegion.checked);

        const parentChecked = selectedSubRegions?.length === formattedSubRegion?.length;
        const parentIndeterminate =
          formattedSubRegion.filter((subRegion) => subRegion.indeterminate)?.length > 0 ||
          (selectedSubRegions?.length && selectedSubRegions?.length < formattedSubRegion?.length);

        result = {
          ...result,
          has_sub_region,
          subGeo: formattedSubRegion,
          checked: parentChecked,
          indeterminate: parentIndeterminate,
        };
      }
      return result;
    });
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "OCE", page: location.pathname });

    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_PARAMETER_VALUES)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "OCE", page: "OCE" });

      if (filterName === "brand") {
        const brandValue = optionValue.toLowerCase().includes("datsun") ? NISSAN : optionValue; //Resets datsun to Nissan

        Promise.all([
          dispatch(setSpecificParameter(filterName, brandValue)),
          dispatch(setSpecificParameter("region", ALL_OPTION)),
          dispatch(setSpecificParameter("market", ALL_COUNTRIES)),
        ]);
      } else {
        dispatch(setSpecificParameter(filterName, optionValue));
      }
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const handleChannelOptionsClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const channelValue = dataset?.channel;
    const breakdownValue = dataset?.channelBreakdown;

    if (channelValue) {
      eventTracking(MixpanelEvents.filter_change, { filter: "channel", value: channelValue, dashboard: "OCE", page: "OCE" });
    }

    channelValue && dispatch(setSpecificParameter("last_touch_marketing_channel", channelValue));
    breakdownValue && dispatch(setSpecificParameter("last_touch_marketing_channel_breakdown", breakdownValue));
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  return (
    <FiltersLayout resetFilters={resetFilters} extraClass={"digital_filters"}>
      <>
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DateFilter
            onFilterClick={showFilterOptionsFn}
            renderOptions={onRenderOptions}
            value={dateRangeParamValue}
            maxDate={dataRefresh}
            availableDates={marketDataAvailability}
            dateOptions={OCE_DATE_FILTER_OPTIONS}
            page="Online CE performance"
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={brands}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/* Geography */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {/* @ts-ignore */}
          <NewGeographyFilter geographies={testedGeographies} onShowFilterOptions={showFilterOptionsFn} value={regionCountryValue} />
        </ErrorBoundary>

        {/*Model*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ModelFilter filterValue={modelParamValue} list={modelList} handleFilterOptionClick={handleFilterOptionClick} />
        </ErrorBoundary>

        {/*Channel*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ChannelFilter
            filterValue={channelValue}
            list={channelList}
            handleChannelOptionsClick={handleChannelOptionsClick}
            showFilterOptions={showFilterOptionsFn}
          />
        </ErrorBoundary>
      </>
    </FiltersLayout>
  );
});
