import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

export const PurchaseFunnelData = (): JSX.Element => {
  const parameters = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters);

  return (
    <div className="data_documentation">
      <h3>PURCHASE FUNNEL - DATA OVERVIEW</h3>

      <h4>Purchase funnel KPI definitions and sources</h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>Element</th>
            <th>Definition</th>
            <th>Provided by</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>% Digitally influenced sales</td>
            <td>Digitally influenced sales / Total private sales</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Digitally influenced sales (DIS)</td>
            <td>Number of confirmed new car sales that can be attributed to OEM managed digital activity</td>
            <td>Market</td>
          </tr>
          <tr>
            <td>Non-digitally influenced sales</td>
            <td>Sales tracked by market - Digitally influenced sales</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Untracked private sales</td>
            <td>Total private sales - Sales tracked by market</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Total private sales</td>
            <td>Digitally influenced sales + Others (dealer walk ins + other)</td>
            <td>Global Sales Team</td>
          </tr>
          <tr>
            <td>Digitally influenced close rate</td>
            <td>Digitally influenced sales / Digital leads</td>
            <td>Calculated</td>
          </tr>
          <tr>
            <td>Leads</td>
            <td>Visitors who express interest (lead-type actions) and leave PII data</td>
            <td>Market</td>
          </tr>
          <tr>
            <td>Brand NABR leads/Tier 1 leads</td>
            <td>Visitors to the brand website who submit a lead form tracked by PACE</td>
            <td>Market</td>
          </tr>
          <tr>
            <td>Third party leads</td>
            <td>Leads from independent websites which are purchased by Nissan and distributed to Dealers</td>
            <td>Market</td>
          </tr>
          <tr>
            <td>Dealer website leads</td>
            <td>Visitors to dealer websites controlled / influenced by Nissan, who perform lead-type actions</td>
            <td>Market</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
