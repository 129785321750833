import { differenceInMonths, differenceInQuarters, differenceInWeeks, parse } from "date-fns";
import {
  ALL_OPTION,
  DEFAULT_LANGUAGE,
  DEFAULT_PRECISION,
  DEFAULT_URL,
  format_float_options,
  format_integer_options,
  format_percent_options,
  OCE_DATE_FILTER_OPTIONS,
} from "../constants";

const PoPLabelMapping: Record<string, string> = {
  monthly: "MoM",
  quarterly: "QoQ",
};

export const isNull = (a: any) => a === null;

export const toFixed = (num: number, precision: number = DEFAULT_PRECISION) => {
  if (isNull(num) || isNaN(num)) return null;
  return +(Math.round(+(num + "e" + precision)) + "e" + -precision);
};

export const stringToBooleanValue = (value: string): boolean => value === "true" || value === "True";

export const searchStringToObject = (searchString: string) => {
  if (!searchString) return {};
  const urlParams = new URLSearchParams(searchString);

  // @ts-ignore
  return Object.fromEntries(urlParams);
};

export const getPathnameAndParams = (profile: string | undefined) => {
  if (!profile) return { pathname: DEFAULT_URL, parameters: "" };

  if (!profile.includes("?")) return { pathname: profile, parameters: "" };

  const splitProfile = profile.split("?");
  return { pathname: splitProfile?.[0], parameters: splitProfile?.[1] };
};

export const toLocaleInteger = (num: number) => {
  return num.toLocaleString(DEFAULT_LANGUAGE, format_integer_options);
};

export const toLocaleFloat = (num: number, maximumFractionDigits?: number) => {
  return maximumFractionDigits
    ? num.toLocaleString(DEFAULT_LANGUAGE, { maximumFractionDigits, minimumFractionDigits: 1 })
    : num.toLocaleString(DEFAULT_LANGUAGE, format_float_options);
};

export const toLocalePercent = (num: number) => {
  return num.toLocaleString(DEFAULT_LANGUAGE, format_percent_options);
};

export const convertToPercentage = (value: number | null, precision = DEFAULT_PRECISION, testValue = true) => {
  if (typeof value !== "number" || isNull(value)) return "n/a";

  if (testValue) {
    if (value > 9.99 || value < -9.99) return "n/a";
  }
  const yoy_comparison = value.toLocaleString(DEFAULT_LANGUAGE, {
    ...format_percent_options,
    maximumFractionDigits: precision,
  });
  return yoy_comparison.replace(/,/g, "");
};

export const convertToAbsolutePercentage = (value: number | null, precision = DEFAULT_PRECISION) => {
  if (typeof value !== "number" || isNull(value)) return "n/a";

  if (value > 9.99 || value < -9.99) return "n/a";

  value = Math.abs(value);

  const yoy_comparison = value.toLocaleString(DEFAULT_LANGUAGE, {
    ...format_percent_options,
    maximumFractionDigits: precision,
  });
  return yoy_comparison.replace(/,/g, "");
};

export const convertToAbsolutePts = (value: number | null, precision = DEFAULT_PRECISION) => {
  if (typeof value !== "number" || isNull(value)) return "n/a";

  value = Math.abs(value);

  return `${value.toFixed(precision)} PTS`;
};

export const convertToAbsoluteFloat = (value: number | null, precision = DEFAULT_PRECISION, isPercentage = false) => {
  if (typeof value !== "number" || isNull(value)) return "n/a";

  value = Math.abs(value);

  return isPercentage ? `${value.toFixed(precision)}%` : value.toFixed(precision);
};

/*
 * Adds a plus sign to the beginning of a positive number
 * @param num
 * @returns {string}*/
export const plusSignNumbers = (num: number | string | null | undefined) => {
  if (typeof num === "undefined") return "n/a";
  if (num === null) return "n/a";

  const value = parseFloat(String(num));
  if (value > 0) {
    return "+" + toLocaleFloat(value);
  }

  return `${toLocaleFloat(value)}`;
};

export const getRatingsClass = (value: number, isPercentage = false) => {
  let rating = "na";

  if ((!isPercentage && (value > 9.99 || value < -9.99)) || (isPercentage && (value > 999 || value < -999))) {
    rating = "na";
  } else if ((!isPercentage && value >= 0.005) || (isPercentage && value >= 0.5)) {
    rating = "up";
  } else if ((!isPercentage && value <= -0.005) || (isPercentage && value <= -0.5)) {
    rating = "down";
  }

  return rating;
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isValidEmail = (email: string) => {
  const emailRe = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/gi;
  return emailRe.test(email);
};

export const sameDay = (d1: Date | null, d2: Date | null) => {
  if (d1 && d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }
  return false;
};

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

/**
 *
 * @param key The key to sort by
 * @param order The order of the results (Ascending or descending)
 * Sort function which is used to sort an array of objects whose values are either strings or numbers
 */

export function compareValues(key: string, order = "asc") {
  return function innerSort(a: any, b: any): any {
    // eslint-disable-next-line no-prototype-builtins
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const sorter = (a: any, b: any) => {
  if (a.year !== b.year) {
    return a.year - b.year;
  } else {
    return months.indexOf(a.month) - months.indexOf(b.month);
  }
};

//Function to move item from one position to another in an array
export function move(array: any[], from: number, to: number) {
  if (to === from) return array;

  const target = array[from];
  const increment = to < from ? -1 : 1;

  for (let k = from; k != to; k += increment) {
    array[k] = array[k + increment];
  }
  array[to] = target;
  return array;
}

// function to get visits annotations api call query parameters
export const getVisitsAnnotationApiParams = (parameters: any, specificParams: string[]) => {
  if (parameters.region == ALL_OPTION && parameters.market == "All countries") {
    return { ...parameters, region: specificParams.join(",") };
  } else if (parameters.region != ALL_OPTION && parameters.market == "All countries") {
    return { ...parameters, region: ALL_OPTION, market: specificParams.join(",") };
  } else {
    return { ...parameters, model: specificParams.join(",") };
  }
};

export const isNullArrayKey = (key: string, data: Array<Record<string, any>>) => {
  /**
   *
   * @param key The key to check if null
   * @param data The data containing objects with the key
   * Function to check if all values of a key in a list of object is null
   */

  const boolArr = data.map((dataItem) => {
    if (dataItem[key]) {
      return true;
    }
    return false;
  });

  return !boolArr.some((value) => value);
};

export const getGranularityOptions = (dateValue: string) => {
  /**
   *
   * @param dataValue The date range value selected by the user
   * Function to return the granularity options in the filters based on the date range selected by the user
   */
  const granularityOptionsMapping = {
    weekly: [...OCE_DATE_FILTER_OPTIONS.filter((val: string) => !val.includes("Last week"))],
    monthly: [...OCE_DATE_FILTER_OPTIONS.filter((val: string) => !val.includes("Last week"))],
    quarterly: [
      ...OCE_DATE_FILTER_OPTIONS.filter((val: string) => !val.includes("quarter") && !val.includes("month") && !val.includes("Last week")),
    ],
  };

  const results = [];

  if (dateValue.includes("-")) {
    const startDate = parse(dateValue.split("-")[0].trim(), "dd/MM/yyyy", new Date());
    const endDate = parse(dateValue.split("-")[1].trim(), "dd/MM/yyyy", new Date());
    const weekDiff = differenceInWeeks(endDate, startDate);
    const monthDiff = differenceInMonths(endDate, startDate);
    const quarterDiff = differenceInQuarters(endDate, startDate);

    if (weekDiff >= 1) {
      results.push("weekly");
    }

    if (monthDiff >= 1) {
      results.push("monthly");
    }

    if (quarterDiff > 1) {
      results.push("quarterly");
    }
  } else {
    Object.entries(granularityOptionsMapping).map(([key, value]: [string, string[]]) => {
      if (value.includes(dateValue)) {
        results.push(key);
      }
    });
  }

  return results;
};

export const getGranularityFromDate = (dateValue: string, defaultGranularity?: string) => {
  const granularityOptionsMapping = {
    weekly: [...OCE_DATE_FILTER_OPTIONS.filter((val: string) => val.includes("week"))],
    monthly: [...OCE_DATE_FILTER_OPTIONS.filter((val: string) => val.includes("month"))],
    quarterly: [...OCE_DATE_FILTER_OPTIONS.filter((val: string) => val.includes("quarter"))],
    yearly: [...OCE_DATE_FILTER_OPTIONS.filter((val: string) => val.includes("year"))],
  };

  for (const granularity in granularityOptionsMapping) {
    //@ts-ignore
    if (granularityOptionsMapping[granularity].includes(dateValue)) {
      return granularity;
    }
  }
  return defaultGranularity ? defaultGranularity : "weekly";
};

export const getValueOrNull = (obj: Record<any, any>, key: string) => {
  if (obj && Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  } else {
    return null;
  }
};

export const getValueOrNa = (obj: Record<any, any>, key: string) => {
  if (obj && Object.prototype.hasOwnProperty.call(obj, key) && obj[key] != null) {
    return obj[key];
  } else {
    return "n/a";
  }
};

export const getPoPLabel = (dateValue: string, defaultGranularity?: string) => {
  const granularity = getGranularityFromDate(dateValue, defaultGranularity);
  return PoPLabelMapping[granularity];
};

export const defaultFiltersSet = (dashboard: string) => {
  if (window.localStorage.getItem("LAST_VISITED_DASHBOARD") != dashboard || !window.localStorage.getItem("LAST_VISITED_DASHBOARD")) {
    return false;
  }
  return true;
};

export const currentDefaultFiltersSet = (dashboard: string) => {
  window.localStorage.setItem("LAST_VISITED_DASHBOARD", dashboard);
};
