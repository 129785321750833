import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { CCS_ANALYSIS_COLORS } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; color?: string }>;
  theme: ThemeInt;
  chartId: string;
  isPercentage?: boolean;
  isLoading: boolean;
  showCurrency?: boolean;
  currency: string;
}

class JourneyExcellenceLineTrendChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, isPercentage, showCurrency, currency } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0";

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.colors.list = CCS_ANALYSIS_COLORS.map((item) => color(item));
    this.chart.legend.marginLeft = 0;

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      if (showCurrency) {
        return `${currency}${text}`;
      }
      return text + `${isPercentage ? "%" : ""}`;
    });

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList?.forEach(({ name, field, color: seriesColor }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      if (field.includes("bp")) {
        series.strokeDasharray = "2,4";
      }
      if (seriesColor) {
        series.fill = color(seriesColor);
        series.stroke = color(seriesColor);
      }

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
      });
      circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM YYYY')}: [/ font-size: var(--regular_font_size)] ${
        showCurrency ? currency : ""
      }{valueY.formatNumber('###,###')}${isPercentage ? "%" : ""}`;
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: RootStateOrAny) {
  const currency = state.journey_excellence.currency;
  return {
    currency,
  };
}

export default connect(mapStateToProps)(JourneyExcellenceLineTrendChart);
