/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFilters, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import "../../../../assets/styles/component/tables.scss";
import { getDateDependentValues } from "../../../../pages/Online CE Performance/subs/helpers";
import seoCustomSortTypes from "./seoSortTypes";

interface DefaultTableProps {
  renderRowSubComponent?: any;
  data: any;
  sortBy: any;
  showHidden: any;
  selected: any;
  columns: any;
  sortByValue?: string;
  showChildRows: boolean;
  canSort: boolean;
  impressionsChecked?: any;
  filters?: any;
  filter?: string;
  isVisitsQuery?: boolean;
}

// @ts-ignore
const SeoDefaultTable = (Props: DefaultTableProps): JSX.Element => {
  const {
    data,
    columns: chartColumns,
    sortBy,
    showHidden,
    selected,
    renderRowSubComponent,
    sortByValue,
    showChildRows,
    canSort,
    filter,
    filters,
    isVisitsQuery,
  } = Props;
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.date_range);
  const { showPop: popVisible } = getDateDependentValues(dateRangeParamValue);

  const sortTypes = useMemo(() => seoCustomSortTypes(sortByValue), [sortByValue]);

  const customGlobalFilterFunction = useCallback(
    (rows: Array<{ values: { queryColumn: string } }>, ids, query: string) => {
      if (!query) return rows;

      const expression = new RegExp(query);
      const startsWithExp = new RegExp(`^${query}`);

      switch (filters) {
        case "CONTAINS":
          return rows.filter((row) =>
            isVisitsQuery
              ? expression.test(row.values.queryColumn) || expression.test(row.values.queryColumn.substring(8).replace("www.", ""))
              : expression.test(row.values.queryColumn)
          );

        case "STARTS_WITH":
          return rows.filter((row) =>
            isVisitsQuery
              ? startsWithExp.test(row.values.queryColumn) || startsWithExp.test(row.values.queryColumn.substring(8).replace("www.", ""))
              : startsWithExp.test(row.values.queryColumn)
          );

        case "EQUAL":
          return rows.filter((row) =>
            isVisitsQuery
              ? row.values.queryColumn === query || row.values.queryColumn.substring(8).replace("www.", "") === query
              : row.values.queryColumn === query
          );

        case "NOT_CONTAINS":
          return rows.filter((row) =>
            isVisitsQuery
              ? !expression.test(row.values.queryColumn) || !expression.test(row.values.queryColumn.substring(8).replace("www.", ""))
              : !expression.test(row.values.queryColumn)
          );

        case "NOT_STARTS_WITH":
          return rows.filter((row) =>
            isVisitsQuery
              ? !startsWithExp.test(row.values.queryColumn) || !startsWithExp.test(row.values.queryColumn.substring(8).replace("www.", ""))
              : !startsWithExp.test(row.values.queryColumn)
          );

        case "NOT_EQUAL":
          return rows.filter((row) =>
            isVisitsQuery
              ? row.values.queryColumn !== query || row.values.queryColumn.substring(8).replace("www.", "") !== query
              : row.values.queryColumn !== query
          );

        case "REGEX":
          return rows.filter((row) =>
            isVisitsQuery
              ? expression.test(row.values.queryColumn) || expression.test(row.values.queryColumn.substring(8).replace("www.", ""))
              : expression.test(row.values.queryColumn)
          );

        case "REGEX_NO_MATCH":
          return rows.filter((row) =>
            isVisitsQuery
              ? !expression.test(row.values.queryColumn) && !expression.test(row.values.queryColumn.substring(8).replace("www.", ""))
              : !expression.test(row.values.queryColumn)
          );

        default:
          return rows;
      }
    },

    [filters]
  );
  const chartInitialState =
    typeof sortBy !== "undefined" && sortBy?.length === 0
      ? {
          initialState: {
            pageSize: 20,
            hiddenColumns: popVisible
              ? showHidden
                ? []
                : ["popComparisonColumn", "yoyColumn"]
              : showHidden
              ? ["popComparisonColumn", "popPercentageColumn"]
              : ["popComparisonColumn", "popPercentageColumn", "yoyColumn"],
          },
        }
      : {
          initialState: {
            sortBy,
            pageSize: 20,
            hiddenColumns: popVisible
              ? showHidden
                ? []
                : ["popComparisonColumn", "yoyColumn"]
              : showHidden
              ? ["popComparisonColumn", "popPercentageColumn"]
              : ["popComparisonColumn", "popPercentageColumn", "yoyColumn"],
          },
        };

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 10, // minWidth is only used as a limit for resizing
      width: 10, // width is used for both the flex-basis and flex-grow
      maxWidth: 10, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setHiddenColumns,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex },
  } = useTable<any>(
    {
      columns: chartColumns,
      data,
      ...chartInitialState,
      sortTypes,
      disableSortBy: selected !== "All" || !canSort,
      defaultColumn,
      // @ts-ignore
      globalFilter: customGlobalFilterFunction,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout
  );
  useEffect(() => {
    let hiddenColumns: string[] = [];
    if (popVisible) {
      if (showHidden) {
        hiddenColumns = [];
      } else {
        hiddenColumns = ["popComparisonColumn", "yoyColumn"];
      }
    } else {
      if (showHidden) {
        hiddenColumns = ["popComparisonColumn", "popPercentageColumn"];
      } else {
        hiddenColumns = ["popComparisonColumn", "popPercentageColumn", "yoyColumn"];
      }
    }
    setHiddenColumns(hiddenColumns);
  }, [popVisible, setHiddenColumns, showHidden]);

  useEffect(() => {
    setGlobalFilter(filter); // Set the Global Filter to the filter prop.
  }, [filter, setGlobalFilter]);

  //Variable to check if a user has filtered records that do not exist
  const noMatchingRecords = data?.length > 1 && rows?.length === 0;

  return data.length === 0 ? (
    <p>No table data</p>
  ) : noMatchingRecords ? (
    <div>
      <p className={"filter_reset"}>There are no records matching this search criteria. Kindly update the filter selection.</p>
    </div>
  ) : (
    <>
      <table {...getTableProps()} className={showHidden ? "expanded-table" : ""}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                const sortClass = column?.isSorted ? (column.isSortedDesc ? "down" : "up") : "";
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${column.canSort ? "sortable" : ""} ${sortClass}`}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, index: any) => {
            const rowClass = (index + 1) % 2 === 0 ? "even" : "odd";
            prepareRow(row);
            return (
              <Fragment key={row.id}>
                <tr
                  {...row.getRowProps()}
                  className={`${rowClass} ${selected === "All" ? "" : selected === row.original?.hostname ? "active" : "disabled"}
                 parent-row`}
                >
                  {row.cells.map((cell: any) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
                {showChildRows && (
                  <tr className="child-row">
                    <td colSpan={visibleColumns.length} className="child-td">
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <span>
          <strong>
            Page {pageIndex + 1} / {pageOptions.length}
          </strong>
        </span>
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className={canPreviousPage ? "active" : ""}>
          {"<"}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage} className={canNextPage ? "active" : ""}>
          {">"}
        </button>
      </div>
    </>
  );
};

export default SeoDefaultTable;
