export const cockpitDataDefinition = [
  {
    kpi: "Digital spend",
    definition:
      "Media FMI allocated through digital channels to reach its marketing goals or promote the brand messaging. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP.",
    source: "MIDAS",
  },
  {
    kpi: "Digital spends vs BP",
    definition:
      "Comparison between planned and actual for digital spend. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP.",
    source: "MIDAS",
  },
  {
    kpi: "Digital spend share",
    definition:
      "Percentage of the total Media Buy that was allocated to Digital Spend. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP.",
    source: "MIDAS",
  },
  {
    kpi: "Website visits",
    definition: "Website visit happens every time the site has at least one page fully loaded.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Cost per visit",
    definition: "Cost for each website visit based on the Media FMI allocated through digital channels",
    source: "MIDAS and Adobe Analytics",
  },
  {
    kpi: "KBA visits",
    definition:
      "KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search. ",
    source: "Adobe Analytics",
  },
  { kpi: "Website visits to KBA visits", definition: "Percentage of visits that have performed a KBA.", source: "Adobe Analytics" },
  {
    kpi: "Cost per KBA visit",
    definition: "Cost for each KBA visit based on the Media FMI allocated through digital channels",
    source: "MIDAS and Adobe Analytics",
  },
  {
    kpi: "Leads visits",
    definition:
      "Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Website visits to Leads visits",
    definition: "Percentage of website visits that have performed a lead.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Cost per lead visit",
    definition: "Cost for each lead visits based on the Media FMI allocated through digital channels",
    source: "MIDAS and Adobe Analytics",
  },
  { kpi: "Leads", definition: "Visitors who express interest (lead-type actions) and leave PII data", source: "Markets" },
  {
    kpi: "Website visits to Leads",
    definition: "Conversion from website visitst to leads that express interest (lead-type actions) and leave PII data",
    source: "Markets and Adobe Analytics",
  },
  {
    kpi: "Cost per Leads",
    definition: "Cost for lead visits based on the Media FMI allocated through digital channels",
    source: "MIDAS and Markets",
  },
  { kpi: "DIS", definition: "Number of confirmed new car sales that can be attributed to OEM managed digital activity", source: "Markets" },
  {
    kpi: "Leads to DIS",
    definition:
      "Conversion from Visitors who express interest (lead-type actions) and leave PII data into car sales that can be atributed to OEM managed digital actiivty",
    source: "Markets",
  },
  {
    kpi: "Cost per DIS",
    definition: "Cost per DIS based on the Media FMI allocated through digital channels",
    source: "MIDAS and Markets",
  },
  {
    kpi: "Website visit per product",
    definition: "Counts every time the site has at least one page fully loaded for the selected product",
    source: "Adobe Analytics",
  },
];
export const fmiDataDefinition = [
  {
    kpi: "Digital spend",
    definition:
      "Media FMI allocated through digital channels to reach its marketing goals or promote the brand messaging. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP.",
    source: "MIDAS",
  },
  {
    kpi: "Digital spends vs BP",
    definition:
      "Comparison between planned and actual for digital spend. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP.",
    source: "MIDAS",
  },
  {
    kpi: "Digital spend share",
    definition:
      "Percentage of the total Media Buy that was allocated to Digital Spend. Amount is based on the most recent year scenario available for the selected FY. Forex Rate based from latest BP.",
    source: "MIDAS",
  },
  {
    kpi: "Website visits",
    definition: "Website visit happens every time the site has at least one page fully loaded.",
    source: "Adobe Analytics",
  },
  {
    kpi: "KBA visits",
    definition:
      "KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search. ",
    source: "Adobe Analytics",
  },
  {
    kpi: "Leads visits",
    definition:
      "Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve.",
    source: "Adobe Analytics",
  },
  { kpi: "Leads", definition: "Visitors who express interest (lead-type actions) and leave PII data", source: "Markets" },
  { kpi: "DIS", definition: "Number of confirmed new car sales that can be attributed to OEM managed digital activity", source: "Markets" },
];
export const onlineJourneyDataDefinition = [
  {
    kpi: "Website visits",
    definition: "Website visit happens every time the site has at least one page fully loaded.",
    source: "Adobe Analytics",
  },
  {
    kpi: "KBA visits",
    definition:
      "KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search. ",
    source: "Adobe Analytics",
  },
  {
    kpi: "Leads visits",
    definition:
      "Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Cost per visit",
    definition: "Cost for each website visit based on the Media FMI allocated through digital channels",
    source: "MIDAS and Adobe Analytics",
  },
  {
    kpi: "Cost per KBA visit",
    definition: "Cost for each KBA visit based on the Media FMI allocated through digital channels",
    source: "MIDAS and Adobe Analytics",
  },
  {
    kpi: "Cost per lead visit",
    definition: "Cost for each lead visits based on the Media FMI allocated through digital channels",
    source: "MIDAS and Adobe Analytics",
  },
  { kpi: "Leads", definition: "Visitors who express interest (lead-type actions) and leave PII data", source: "Markets" },
  { kpi: "DIS", definition: "Number of confirmed new car sales that can be attributed to OEM managed digital activity", source: "Markets" },
  { kpi: "Website visits to KBA visits", definition: "Percentage of visits that have performed a KBA.", source: "Adobe Analytics" },
  {
    kpi: "Website visits to Leads visits",
    definition: "Percentage of website visits that have performed a lead.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Website visits to Leads",
    definition: "Conversion from website visitst to leads that express interest (lead-type actions) and leave PII data",
    source: "Markets and Adobe Analytics",
  },
  {
    kpi: "Leads to DIS",
    definition:
      "Conversion from Visitors who express interest (lead-type actions) and leave PII data into car sales that can be atributed to OEM managed digital actiivty",
    source: "Markets",
  },
  { kpi: "KBA visits to leads visits", definition: "Converion from KBA visits to Lead visits.", source: "Adobe Analytics" },
  { kpi: "Leads visits to leads", definition: "Conversion from Lead visits to Leads.", source: "Markets and Adobe Analytics" },
  {
    kpi: "Metric channel share - visits share",
    definition: "Share of each channel in the total number of website visits.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Metric channel share - KBA conversion",
    definition: "Share of each channel in the conversion of website visits to KBA visits.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Customer experience DXS",
    definition: "Evalution that customers have from the website. Scores can go from 0 to 10.",
    source: "Decibel",
  },
  {
    kpi: "Website star rating Average raiting",
    definition: "Average out of 5 of all ratings given by users to evaluate their experience on the site",
    source: "Getfeedback platform",
  },
  { kpi: "Website star rating # feedbacls", definition: "Number of people who rated their experience", source: "Getfeedback platform" },
  {
    kpi: "Exit survey Proportional objective completion",
    definition: "People who claimed they achieved their objective on the website",
    source: "Getfeedback platform",
  },
  { kpi: "Exit survey # feedbacks", definition: "Number of people who answered to the survey", source: "Getfeedback platform" },
];
export const leadsManagementSystemDataDefinition = [
  {
    kpi: "% Digitally Influenced Sales",
    definition: "Sum of Digitally Influenced Sales divide by the Private sales. The result is given in percentage",
    source: "Markets",
  },
  {
    kpi: "Digitially Influenced Sales",
    definition: "Number of confirmed new car sales that can be attributed to OEM managed digital activity",
    source: "Markets",
  },
  { kpi: "Leads Volume", definition: "Visitors who express interest (lead-type actions) and leave PII data", source: "Markets" },
  {
    kpi: "Private Sales",
    definition: "Sum of all sales in the market ( Digitally Influenced Sales + Dealer Walk ins + Other)",
    source: "Markets",
  },
  {
    kpi: "Website visits to Leads",
    definition: "Conversion from website visitst to leads that express interest (lead-type actions) and leave PII data",
    source: "Markets and Adobe Analytics",
  },
  {
    kpi: "Cost per Leads",
    definition: "Cost for lead visits based on the Media FMI allocated through digital channels",
    source: "Markets and MIDAS",
  },
  {
    kpi: "Leads to DIS",
    definition:
      "Conversion from Visitors who express interest (lead-type actions) and leave PII data into car sales that can be atributed to OEM managed digital actiivty",
    source: "Markets",
  },
  {
    kpi: "Cost per DIS",
    definition: "Cost per DIS based on the Media FMI allocated through digital channels",
    source: "Markets and MIDAS",
  },
];
export const productJourneyDataDefinition = [
  {
    kpi: "Website visits per product",
    definition: "Counts every time the site has at least one page fully loaded for the selected product",
    source: "Adobe Analytics",
  },
  {
    kpi: "KBA visits per product",
    definition:
      "Key buying actions for the specific product: model detail comparison, saved searches views, compare tools views, dealer search, etc.",
    source: "Adobe Analytics",
  },
  {
    kpi: "Leads visits per product",
    definition: "Volume of visits including one digital lead in the product page",
    source: "Adobe Analytics",
  },
  { kpi: "Most popular entry points", definition: "Top Landing page per product.", source: "Adobe Analytics" },
  { kpi: "Most popualr exit points", definition: "Top Exit point per product.", source: "Adobe Analytics" },
];
